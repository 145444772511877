import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { TransitionProps } from "@mui/material/transitions";
import SignUpPayment from "@app.automotus.io/components/routes/signup/payment";
import { formatCurrency } from "common/format";
import DialogContent from "@mui/material/DialogContent";

const SIGNUP_HEADER_HEIGHT_DESKTOP = 70;
const SIGNUP_HEADER_HEIGHT_MOBILE = 62;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/** A component that displays a modal allowing the user to make payment. */
export const PaymentModal: React.FC<PaymentModalProps> = ({ amount, onClose = () => undefined, open = true }) => {
  const theme = useTheme();
  const isTiny = useMediaQuery(theme.breakpoints.down("tiny"));
  const appbarHeight = isTiny ? SIGNUP_HEADER_HEIGHT_MOBILE : SIGNUP_HEADER_HEIGHT_DESKTOP;
  return (
    <Dialog
      BackdropProps={{ invisible: true }}
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          mt: `${appbarHeight + 32}px !important`,
          maxWidth: "100%",
          width: "100%",
          mx: 0,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          mb: 0,
        },
      }}
      scroll={"paper"}
    >
      <AppBar
        sx={{
          height: "52px",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
          <Box>
            <Typography variant="body3" sx={{ fontWeight: 500 }}>
              Pay ${formatCurrency(amount)}
            </Typography>
          </Box>
          <Box>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <Box
                sx={{
                  width: "28px",
                  height: "28px",
                  borderRadius: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.135)",
                }}
              >
                <CloseIcon sx={{ fontSize: "14px" }} />
              </Box>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ mb: 1.25, px: 3, pt: 0 }}>
        <SignUpPayment isValid={true} isTryingForFree={!amount} />
      </DialogContent>
    </Dialog>
  );
};

/** Props passed to initialize an {@link PaymentModal} component */
export interface PaymentModalProps {
  /** handler fired when user requests to close modal */
  onClose?: () => void;
  /** open state */
  open?: boolean;
  /** Pay amount */
  amount: number;
}

export default PaymentModal;
