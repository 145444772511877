import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GetAllInvoicesData } from "common/graphql";
import Fade from "@mui/material/Fade";
import SupportIcon from "@app.automotus.io/components/icons/SupportIcon";

export const DesktopNavBarItem: React.FC<DesktopNavItemProps> = ({
  title,
  to,
  onClick,
  invoicesData,
  invoicesLoading,
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  const isInvoicesDropDownMenu = to === "/invoices";
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const invoicesSubMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleInvoicesSubMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleInvoicesSubMenuClose = () => {
    setAnchorEl(null);
  };
  const handleInvoicesSubMenuItemClicked = (vehicleId: string | undefined) => {
    if (vehicleId === undefined) {
      return;
    }
    handleInvoicesSubMenuClose();
    navigate(`/vehicles/${vehicleId}/invoices`);
  };

  const invoices = [...(invoicesData?.invoices || [])];
  invoices.sort((invoiceA, invoiceB) => {
    if (invoiceA.amount_paid === 0) return -1;
    if (invoiceB.amount_paid === 0) return 1;
    return 0;
  });

  return (
    <>
      <ButtonBase
        to={to}
        component={Link}
        target={title === "Support" ? "_blank" : undefined}
        sx={(theme) => ({
          display: "inline-flex",
          px: 2.5,
          pt: 1.25,
          pb: 1,
          ":hover": {
            bgcolor: theme.palette.grey[100],
          },
          ...(match && {
            borderBottom: 2,
            borderBottomColor: "primary.main",
          }),
          color: (theme) => (title === "Logout" ? theme.palette.primary.main : theme.palette.text.primary),
        })}
        id={isInvoicesDropDownMenu && invoicesSubMenuOpen ? "fade-button" : to}
        aria-controls={isInvoicesDropDownMenu && invoicesSubMenuOpen ? "fade-menu" : undefined}
        aria-haspopup={isInvoicesDropDownMenu && invoicesSubMenuOpen ? "true" : "false"}
        aria-expanded={isInvoicesDropDownMenu && invoicesSubMenuOpen ? "true" : undefined}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          if (isInvoicesDropDownMenu) {
            e.preventDefault();
            handleInvoicesSubMenuClick(e);
          } else if (onClick) {
            e.preventDefault();
            onClick(e);
          }
        }}
      >
        {title === "Support" && <SupportIcon />}
        <Typography variant="h7" sx={{ ml: title === "Support" ? 2 : 0 }}>
          {title}
        </Typography>
        {isInvoicesDropDownMenu && <KeyboardArrowDownIcon sx={{ ml: 1.5 }} />}
      </ButtonBase>
      {isInvoicesDropDownMenu && invoices.length !== 0 && !invoicesLoading && (
        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          sx={{
            ".MuiList-root": {
              maxHeight: "210px",
              py: "10px",
            },
            ".MuiMenuItem-root": {
              display: "flex",
              justifyContent: "space-between",
            },
          }}
          anchorEl={anchorEl}
          open={invoicesSubMenuOpen}
          onClose={handleInvoicesSubMenuClose}
          TransitionComponent={Fade}
        >
          {invoices.map((invoice) => (
            <MenuItem
              key={invoice.id}
              onClick={() => handleInvoicesSubMenuItemClicked(invoice.registered_vehicle.id)}
              sx={{
                pr: 2,
              }}
            >
              <Typography variant="body1" mr={"52px"}>
                {String(invoice.index).padStart(6, "0")}
              </Typography>
              {invoice.amount_paid === 0 && (
                <Typography variant="caption" color="#E31B0C">
                  Unpaid
                </Typography>
              )}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export interface DesktopNavItemProps {
  title: string;
  to: string;
  onClick?: (e: React.SyntheticEvent) => void;
  invoicesData?: GetAllInvoicesData;
  invoicesLoading?: boolean;
}

export default DesktopNavBarItem;
