import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ContainerProps } from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DesktopNavbar from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageDesktopNavbar";
import MobileNavbar from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageMobileNavbar";
import PartnerLandingPageHowItWorks from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageHowItWorks";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";
import Button from "@mui/material/Button";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import { useActivePayee } from "@app.automotus.io/components/hooks/useActivePayee";

export const BillByMailLayout: React.FC<ContainerProps> = ({ children }) => {
  const { activePayee } = useActivePayee();
  const payeeAccountId = activePayee ? activePayee.payeeAccountId : process.env.REACT_APP_PITTSBURGH_ACCOUNT_ID ?? "";

  const theme = useTheme();
  const [parkingInfoModalOpen, setParkingInfoModalOpen] = useState(false);
  const [howItWorksSheetOpen, setHowItWorksSheetOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isWide = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

  const handleOpenHowItWorksSheet = () => {
    setHowItWorksSheetOpen(true);
  };

  const handleCloseHowItWorksSheet = () => {
    setHowItWorksSheetOpen(false);
  };

  const handleOpenParkingInfoModal = () => {
    setParkingInfoModalOpen(true);
  };

  const handleCloseParkingInfoModal = () => {
    setParkingInfoModalOpen(false);
  };

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
      }}
    >
      {isWide && <DesktopNavbar openParkingInfoModal={handleOpenParkingInfoModal} />}
      {isMobile && (
        <MobileNavbar openHowItWorks={handleOpenHowItWorksSheet} openParkingInfoModal={handleOpenParkingInfoModal} />
      )}
      {isMobile && (
        <BottomSheet
          marginTopSmall={45}
          marginTopMedium={49}
          open={howItWorksSheetOpen}
          onClose={handleCloseHowItWorksSheet}
        >
          <PartnerLandingPageHowItWorks />
          <Box sx={{ flex: 1 }} />
          <Button
            variant={"contained"}
            fullWidth
            onClick={handleCloseHowItWorksSheet}
            sx={{
              m: 2,
              height: isSmall ? "30px" : "36px",
              fontSize: isSmall ? "14px" : "16px",
              fontWeight: 600,
              textTransform: "none",
              width: `auto`,
            }}
          >
            Back
          </Button>
        </BottomSheet>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: isWide ? "row" : "column-reverse",
          height: {
            xs: "calc(100% - 26px)",
            tiny: "calc(100% - 14px)",
            sm: "calc(100% - 8px)",
            lg: "calc(100% - 96px)",
          },
        }}
      >
        {children}
        <Outlet />
      </Box>
      <PartnerLandingPageParkingRatesAndHoursModal
        open={parkingInfoModalOpen}
        onClose={handleCloseParkingInfoModal}
        payeeAccountId={payeeAccountId}
      />
    </Box>
  );
};

export type PaddingConfigurableProps = {
  noPadding?: boolean;
};

export default BillByMailLayout;
