import React, { useState, useCallback } from "react";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import Alert from "@mui/material/Alert";
import InfoIcon from "@mui/icons-material/InfoOutlined";

import AmountComponent from "../AmountComponent";
import SeeParkingRates from "../SeeParkingRates";
import { CurrencyTextField } from "@app.automotus.io/components/CurrencyTextField";
import { formatCurrency } from "common/format";

const DEFAULT_AMOUNT = 1500;
const AmountData = [
  {
    amount: 0,
    description: "1 park",
  },
  {
    amount: 500,
    description: "approx. 10 parks",
  },
  {
    amount: DEFAULT_AMOUNT,
    description: "approx. 30 parks",
    isRecommended: true,
  },
];

export enum LastTouchedStatus {
  None,
  Option,
  Other,
}

/**
 * Component that renders a select amount view.
 */
export const SelectAmount: React.FC<SelectAmountProps> = ({
  onChange,
  amount,
  disabled,
  isBillByMail = false,
  minAmount = 500,
  maxAmount = 10000,
}: SelectAmountProps) => {
  const theme = useTheme();
  const [otherAmount, setOtherAmount] = useState<number | null>(null);
  const [lastTouched, setLastTouched] = useState<LastTouchedStatus>(LastTouchedStatus.Option);
  const [lastSelectedItemAmount, setLastSelectedItemAmount] = useState<number>(DEFAULT_AMOUNT);
  const isMobile = useIsMobile();

  const onOtherAmountChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setLastTouched(LastTouchedStatus.Other);
      if (e.target.value === "") {
        setOtherAmount(null);
        onChange(lastSelectedItemAmount, LastTouchedStatus.Option);
        setLastTouched(LastTouchedStatus.Option);
      } else {
        const newAmount: number = +e.target.value;
        setOtherAmount(newAmount);
        onChange(newAmount * 100, LastTouchedStatus.Other);
      }
    },
    [setOtherAmount, onChange, lastSelectedItemAmount],
  );
  const isInvalidAmount = otherAmount !== null && (amount < minAmount || amount > maxAmount);

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="body2" sx={{ fontWeight: 500, fontSize: isMobile ? "14px" : "16px" }}>
        Select Amount
      </Typography>
      <Typography variant="body4" sx={{ fontSize: "0.75rem" }} color={theme.palette.text.secondary}>
        approx. parks based on average park time of 6 min.
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 1, mt: 0.5 }}>
        {AmountData.map(({ amount: itemAmount, description, isRecommended }) => (
          <Box
            key={itemAmount}
            sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", width: "33%" }}
          >
            <AmountComponent
              amount={itemAmount}
              description={description}
              isSelected={lastTouched === LastTouchedStatus.Option && itemAmount === amount}
              onSelect={() => {
                if (disabled) {
                  return;
                }
                onChange(itemAmount, LastTouchedStatus.Option);
                setOtherAmount(null);
                setLastTouched(LastTouchedStatus.Option);
                setLastSelectedItemAmount(itemAmount);
              }}
            />
            {isRecommended && (
              <Typography
                variant="body4"
                color={theme.palette.text.secondary}
                sx={{ fontSize: "10px", fontWeight: 400, fontStyle: "italic" }}
              >
                Most Popular
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      {!isBillByMail && (
        <CurrencyTextField
          fullWidth
          error={isInvalidAmount}
          helperText={
            isInvalidAmount
              ? `Enter an amount between $${formatCurrency(minAmount)} and $${formatCurrency(maxAmount)}`
              : " "
          }
          value={otherAmount === 0 || otherAmount ? otherAmount : ""}
          label={`Other ($${formatCurrency(minAmount)} minimum)`}
          onChange={onOtherAmountChange}
        />
      )}
      {!isBillByMail && <SeeParkingRates />}
      {!isBillByMail && lastTouched === LastTouchedStatus.Option && amount === 0 && (
        <Alert
          icon={<InfoIcon sx={{ fontSize: "20px", color: "#ED6C02" }} />}
          severity="warning"
          sx={{ mb: 3, color: "#ED6C02", alignItems: "center", backgroundColor: "rgba(237, 108, 2, 0.08)" }}
        >
          By selecting 'try for free' your CurbPass won't be loaded until after your next park.
        </Alert>
      )}
    </Box>
  );
};

type SelectAmountProps = {
  amount: number;
  onChange: (value: number, type: number) => void;
  disabled: boolean;
  isBillByMail?: boolean;
  minAmount?: number;
  maxAmount?: number;
};

export default SelectAmount;
