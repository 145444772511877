import React from "react";
import { Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { NavBar } from "@app.automotus.io/components/NavBar";
import Box from "@mui/material/Box";
import { useLogout } from "@app.automotus.io/components/auth";
import SupportIcon from "@app.automotus.io/components/icons/SupportIconMobile";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

export const DashboardLayoutRoute: React.FC = () => {
  const mainRef = React.useRef(null);
  const logout = useLogout();

  const NAV_LINKS = [
    {
      title: "Transactions",
      to: "/dashboard",
    },
    {
      title: "Invoices",
      to: "/invoices",
      icon: <KeyboardArrowRight sx={{ mr: -0.5 }} />,
    },
    {
      title: "Support",
      to: "/support",
      onClick: () => {
        window.open("/support", "_blank");
      },
      icon: <SupportIcon />,
    },
    {
      title: "Account",
      to: "/account",
    },
    {
      title: "Logout",
      to: "/",
      onClick: async (e: React.SyntheticEvent) => {
        e.preventDefault();

        await logout();
      },
    },
  ];

  return (
    <>
      <CssBaseline />
      <NavBar targets={NAV_LINKS} />
      <Box component="main" ref={mainRef}>
        <Outlet />
      </Box>
    </>
  );
};
