import React from "react";
import { DesktopNavBarItem } from "@app.automotus.io/components/NavBar/NavBarItem/DesktopNavBarItem";
import { NavBarTarget } from "@app.automotus.io/components/NavBar";
import Box from "@mui/material/Box";
import { GetAllInvoicesData } from "common/graphql";
import Stack from "@mui/material/Stack";

export const DesktopNavBarMenu: React.FC<DesktopNavBarMenuProps> = ({ invoicesData, invoicesLoading, targets }) => {
  return (
    <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"} alignItems={"center"}>
      <Stack direction={"row"} spacing={2.5}>
        {targets.map((target) => (
          <DesktopNavBarItem
            key={target.to}
            invoicesData={invoicesData}
            invoicesLoading={invoicesLoading}
            {...target}
          />
        ))}
      </Stack>
    </Box>
  );
};

export interface DesktopNavBarMenuProps {
  invoicesData?: GetAllInvoicesData;
  invoicesLoading?: boolean;
  targets: NavBarTarget[];
}
