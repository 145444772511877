import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import PasswordlessPhoneNumberEntry from "./PasswordlessPhoneNumberEntry";
import PasswordlessConfirmationCodeEntry from "./PasswordlessConfirmationCodeEntry";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useWindowDimensions } from "@app.automotus.io/components/hooks";
import Button from "@mui/material/Button";

/** Props passed to intialize a {@link PasswordlessLogin} component */
export interface PasswordlessLoginProps {
  /** Current passwordless login stage */
  stage?: PasswordlessLoginStage;
  /** Phone number input by user */
  phoneNumber?: string;
  /** Change handler fired upon phone number change */
  onChangePhoneNumber?: (phoneNumber: string) => void;
  /** Confirmation code input by user */
  confirmationCode?: string;
  /** Indicates whether an error occurred when getting the confirmation code */
  failedToGetConfirmationCodeError?: boolean;
  /** Change handler fired upon confirmation code change */
  onChangeConfirmationCode?: (confirmationCode: string) => void;
  /** Indicates whether the component is in the loading state */
  loading?: boolean;
  /** Indicates whether the user tried to submit an invalid phone number */
  invalidPhoneNumberError?: boolean;
  /** Phone number submission handler */
  onSubmitPhoneNumber?: (phoneNumber: string) => void;
  /** Indicates whether the user attempted to submit an invalid confirmation code */
  invalidConfirmationCodeError?: boolean;
  /** Confirmation code submission handler */
  onSubmitConfirmationCode?: (confirmationCode: string) => void;
  /** Function that logs the user in using the universal login experience */
  loginWithRedirect?: () => void;
  /** Function that navigates back to the previous screen */
  onBack?: () => void;
}

/** Set of passwordless login stages */
export type PasswordlessLoginStage = "phone_number" | "confirmation_code";

/**
 * Presentational component that renders the passwordless login process
 */
export const PasswordlessLogin: React.FC<PasswordlessLoginProps> = ({
  stage = "phone_number",
  phoneNumber = "",
  onChangePhoneNumber = () => undefined,
  confirmationCode = "",
  onChangeConfirmationCode = () => undefined,
  loading = false,
  invalidPhoneNumberError = false,
  failedToGetConfirmationCodeError = false,
  onSubmitPhoneNumber = () => undefined,
  invalidConfirmationCodeError = false,
  onSubmitConfirmationCode = () => undefined,
  loginWithRedirect,
  onBack,
}) => {
  const theme = useTheme();
  const [useAnotherMethodWidth, setUseAnotherMethodWidth] = useState("auto");
  const { width: windowWidth } = useWindowDimensions();

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (submitButtonRef.current) {
      setUseAnotherMethodWidth(`${submitButtonRef.current.offsetWidth}px`);
    }
  }, [windowWidth]);

  const LoginStageElement =
    stage === "phone_number" ? (
      <PasswordlessPhoneNumberEntry
        ref={submitButtonRef}
        phoneNumber={phoneNumber}
        onChangePhoneNumber={(e) => onChangePhoneNumber(e.target.value)}
        onSubmitPhoneNumber={onSubmitPhoneNumber}
        invalidPhoneNumberError={invalidPhoneNumberError}
        failedToGetConfirmationCodeError={failedToGetConfirmationCodeError}
        loading={loading}
      />
    ) : (
      <PasswordlessConfirmationCodeEntry
        ref={submitButtonRef}
        phoneNumber={phoneNumber}
        confirmationCode={confirmationCode}
        onChangeConfirmationCode={onChangeConfirmationCode}
        onClickGetANewCode={() => {
          onSubmitPhoneNumber(phoneNumber);
        }}
        loading={loading}
        isConfirmationCodeIncorrect={invalidConfirmationCodeError}
      />
    );

  return (
    <React.Fragment>
      <Box sx={{ flex: 1 }}>{LoginStageElement}</Box>

      {stage === "confirmation_code" ? (
        <Box
          sx={{ mt: 1.5, flex: 1, maxWidth: "100%" }}
          gap={"16px"}
          flexDirection={"column"}
          justifyContent={"flex-end"}
          display={"flex"}
        >
          <Button
            sx={{ textTransform: "unset", px: 2, py: 0.75, fontSize: "1rem", width: { sx: useAnotherMethodWidth } }}
            onClick={() => onSubmitConfirmationCode(confirmationCode)}
            disabled={!confirmationCode}
            variant="contained"
          >
            Verify
          </Button>
          <Button
            onClick={() => onBack && onBack()}
            variant="text"
            sx={{
              textTransform: "unset",
              fontFamily: "DM Sans",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "22px",
            }}
          >
            Back
          </Button>
        </Box>
      ) : loginWithRedirect ? (
        <>
          <Divider sx={{ mt: 1.5, "&::before": { mt: -2 }, "&::after": { mt: -2 } }}>
            <Typography sx={{ color: theme.palette.grey[600] }} variant="body4">
              or
            </Typography>
          </Divider>
          <Box sx={{ mt: 1.5 }} display="flex" justifyContent="center">
            <Button
              sx={{ width: useAnotherMethodWidth, textTransform: "unset", px: 2, py: 0.75, fontSize: "1rem" }}
              onClick={() => loginWithRedirect()}
              disabled={loading}
              variant="outlined"
            >
              Use Another Method
            </Button>
          </Box>
        </>
      ) : null}
    </React.Fragment>
  );
};

export default PasswordlessLogin;
