import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import SignUpPayment from "@app.automotus.io/components/routes/signup/payment";
import { TermsOfServiceWrapper } from "@app.automotus.io/components/TermsOfService";
import SelectAmount, { LastTouchedStatus } from "./SelectAmount";
import DiscountComponent from "./DiscountComponent";
import PaymentModal from "./PaymentModal";
import { useMutation } from "@apollo/client";
import {
  CREATE_WALLET,
  CreateWalletData,
  CreateWalletVars,
  GET_USER_PROFILE,
  GET_WALLET_WITH_PAYEE,
} from "common/graphql";
import {
  ACCEPT_TERMS_OF_SERVICE,
  DECLINE_TERMS_OF_SERVICE,
  UpdateTermsOfServiceData,
  UpdateTermsOfServiceAcceptVars,
  UpdateTermsOfServiceDeclineVars,
} from "common/graphql/mutations";
import { useActivePayee } from "../../../hooks/useActivePayee";
import { OnboardingStatus } from "@app.automotus.io/components/common";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";

export const DEFAULT_AMOUNT = 1500;
const MINIMUM_AMOUNT = 500;
const MAXIMUM_AMOUNT = 10000;

export const CurbPassSetup: React.FC = () => {
  const paymentFormRef = useRef<{ scrollIntoPayButton: () => void }>(null);
  const state = useLocation().state as Record<string, unknown>;
  const { userProfile } = useUserProfile();
  const [selectedAmount, setAmount] = useState<number>(DEFAULT_AMOUNT);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const isAgreedToTermsOfService = ((state && state.isAgreedToTermsOfService) ?? false) as boolean;
  const [agreed, setAgreed] = useState<boolean>(isAgreedToTermsOfService);
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState<boolean>(false);
  const [isTosOpen, setIsTosOpen] = useState<boolean>(false);
  const [isValidAmount, setIsValidAmount] = useState<boolean>(true);
  const theme = useTheme();
  const SPACING = 3;
  const isMobile = useIsMobile();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const { activePayee } = useActivePayee();
  const [acceptTermsOfService] = useMutation<UpdateTermsOfServiceData, UpdateTermsOfServiceAcceptVars>(
    ACCEPT_TERMS_OF_SERVICE,
    {
      onError: (err) => {
        console.error(err);
      },
    },
  );
  const [declineTermsOfService] = useMutation<UpdateTermsOfServiceData, UpdateTermsOfServiceDeclineVars>(
    DECLINE_TERMS_OF_SERVICE,
    {
      onError: (err) => {
        console.error(err);
      },
    },
  );
  const [createWallet, { loading: createWalletLoading, reset: resetCreateWallet }] = useMutation<
    CreateWalletData,
    CreateWalletVars
  >(CREATE_WALLET, { refetchQueries: [GET_WALLET_WITH_PAYEE, GET_USER_PROFILE] });
  const navigate = useNavigate();

  const onToggleAgreed = () => {
    setAgreed((prev) => !prev);
  };

  const onClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const onCloseTosModal = () => {
    setIsTosOpen(false);
  };

  const onOpenTosModal = () => {
    setIsTosOpen(true);
  };

  const updateSettings = useCallback(async () => {
    const isFreeTrial = selectedAmount === 0;
    const desiredBalance = isFreeTrial ? 1500 : selectedAmount;
    const minimumBalance = desiredBalance * 0.25;

    try {
      await createWallet({
        variables: {
          payeeAccountId: activePayee?.payeeAccountId || "",
          desiredBalance,
          minimumBalance,
          isFreeTrial,
        },
      });
      resetCreateWallet();
    } catch (err) {
      console.error(err);
    }
  }, [createWallet, resetCreateWallet, activePayee, selectedAmount]);

  useEffect(() => {
    if (isPaymentFormVisible || paymentModalOpen) {
      (async () => {
        await updateSettings();
      })();
    }
  }, [selectedAmount, isPaymentFormVisible, updateSettings, paymentModalOpen]);

  const onAddPayment = async () => {
    await updateSettings();
    if (isMobile) {
      setPaymentModalOpen(true);
      return;
    }
    setIsPaymentFormVisible(true);
    setTimeout(() => {
      paymentFormRef?.current?.scrollIntoPayButton();
    }, 0);
  };

  const onChangeAmount = (value: number, type: number) => {
    setAmount(value);
    setIsValidAmount(type === LastTouchedStatus.Option || (value >= MINIMUM_AMOUNT && value <= MAXIMUM_AMOUNT));
  };

  const onboardingStatusValue = userProfile?.onboardingStatus ?? "profile";
  const isEditable = OnboardingStatus.isBefore(onboardingStatusValue, OnboardingStatus.PAYMENT);

  const onAcceptTOS = async () => {
    const { data, errors } = await acceptTermsOfService({
      variables: {
        userId: userProfile?.id || "",
        tosAcceptedVersion: "2022-03-01",
      },
    });

    if (!data) {
      console.error("Failed to accept TOS", errors);
      return;
    }

    if (data.userProfile.tosAcceptedAt === null) {
      console.error("Failed to accept TOS: tosAcceptedAt is null");
      return;
    }

    if (data.userProfile.tosAcceptedVersion !== "2022-03-01") {
      console.error("Failed to accept TOS: tosAcceptedVersion mismatch");
      return;
    }

    onCloseTosModal();
    setAgreed(true);
    navigate("/signup/payment", {
      state: {
        ...(state as Record<string, unknown>),
        isAgreedToTermsOfService: true,
      },
    });
  };

  const onDeclineTOS = async () => {
    const { data, errors } = await declineTermsOfService({
      variables: {
        userId: userProfile?.id || "",
      },
    });

    if (!data) {
      console.error("Failed to decline TOS", errors);
      return;
    }

    if (data.userProfile.tosAcceptedAt !== null) {
      console.error("Failed to decline TOS: tosAcceptedAt is not null");
      return;
    }

    if (data.userProfile.tosAcceptedVersion !== null) {
      console.error("Failed to decline TOS: tosAcceptedVersion is not null");
      return;
    }

    onCloseTosModal();
    setAgreed(false);
    navigate("/signup/payment", {
      state: {
        ...(state as Record<string, unknown>),
        isAgreedToTermsOfService: false,
      },
    });
  };

  return (
    <Box sx={{ mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      <Typography variant={isDesktop ? "h4" : isSmall ? "h6" : "h5"}>
        Setup <span style={{ color: theme.palette.primary.main }}>CurbPass</span>
      </Typography>
      <Typography variant="body2" sx={{ mb: SPACING }}>
        We’ll auto refill your CurbPass when it gets low. You can request a full refund anytime!
      </Typography>
      <fieldset disabled={!isEditable} style={{ border: 0, padding: 0, margin: 0 }}>
        <SelectAmount
          disabled={!isEditable}
          amount={selectedAmount}
          minAmount={MINIMUM_AMOUNT}
          maxAmount={MAXIMUM_AMOUNT}
          onChange={onChangeAmount}
        />
        <DiscountComponent
          title="Save 75% with CurbPass"
          description="Drivers with CurbPass save up to 75% in parking fees."
        />
        <Box sx={{ mt: 3 }}>
          <Box>
            <Grid container direction="row" alignItems="left" rowSpacing={1} sx={{ pl: 1 }}>
              <Grid item xs={12}>
                <TermsOfServiceCheckbox
                  openTosModal={onOpenTosModal}
                  isChecked={agreed}
                  onToggleCheck={onToggleAgreed}
                />
              </Grid>
            </Grid>
          </Box>
          {!isMobile && isPaymentFormVisible && <Divider sx={{ mt: 3 }} />}
          {(isMobile || !isPaymentFormVisible) && (
            <Stack sx={{ mt: 1 }} spacing={2}>
              <LoadingButton
                id="add-payment-method"
                disabled={!agreed || !isValidAmount}
                onClick={onAddPayment}
                variant="contained"
                fullWidth
                loading={createWalletLoading}
                sx={{
                  textTransform: "unset",
                  py: isMobile ? 0.75 : 1,
                  fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                  fontWeight: 400,
                }}
              >
                Add Payment Method
              </LoadingButton>
              <Button
                onClick={() => navigate("/signup/vehicle")}
                variant={"outlined"}
                fullWidth
                sx={{
                  textTransform: "unset",
                  py: isMobile ? 0.75 : 1,
                  fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                  fontWeight: 400,
                }}
              >
                Back
              </Button>
            </Stack>
          )}
          {!isMobile && isPaymentFormVisible && (
            <SignUpPayment ref={paymentFormRef} isTryingForFree={!selectedAmount} isValid={agreed && isValidAmount} />
          )}
          {isMobile && <PaymentModal amount={selectedAmount} open={paymentModalOpen} onClose={onClosePaymentModal} />}
          {isMobile && (
            <BottomSheet open={isTosOpen} onClose={onCloseTosModal} marginTopSmall={75} marginTopMedium={79}>
              <AppBar
                sx={{
                  height: "52px",
                  position: "relative",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                  <Box>
                    <Typography variant="body3" sx={{ fontWeight: 500, ml: 1 }}>
                      Terms Of Service
                    </Typography>
                  </Box>
                  <Box>
                    <IconButton edge="end" color="inherit" onClick={onCloseTosModal} aria-label="close">
                      <Box
                        sx={{
                          width: "28px",
                          height: "28px",
                          borderRadius: "100px",
                          backgroundColor: "rgba(0, 0, 0, 0.135)",
                        }}
                      >
                        <CloseIcon sx={{ fontSize: "14px" }} />
                      </Box>
                    </IconButton>
                  </Box>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ px: 3, pt: 0, mb: -2.5 }}>
                <TermsOfServiceWrapper variant="select" onAccept={onAcceptTOS} onDecline={onDeclineTOS} />
              </DialogContent>
            </BottomSheet>
          )}
        </Box>
      </fieldset>
    </Box>
  );
};

const TermsOfServiceCheckbox: React.FC<{
  onToggleCheck: () => void;
  isChecked: boolean;
  openTosModal: () => void;
}> = ({ onToggleCheck, isChecked, openTosModal }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox sx={{ fontSize: "1.125rem" }} checked={isChecked} onChange={onToggleCheck} />}
        label={
          <Typography color={theme.palette.text.primary}>
            I agree to the{" "}
            <Link
              component={RouterLink}
              to={isMobile ? "/signup/payment" : "/signup/terms-of-service"}
              state={{ sourceRoute: "/signup/payment" }}
              onClick={() => {
                if (isMobile) {
                  openTosModal();
                }
              }}
            >
              Terms of Service
            </Link>
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default CurbPassSetup;
