import { gql } from "@apollo/client";
import { RegisteredVehicle, registeredVehicleFragment } from "./registeredVehicleFragment";
import { Dispute, disputeFragment } from "./disputeFragment";
import { Park, parkFragment } from "common/graphql/fragments/parkFragment";

export const transactionDetailFragment = gql`
  fragment transactionDetailFragment on financial_transaction {
    id
    index
    addressStreet: address_street
    addressCity: address_city
    addressState: address_state
    park {
      ...parkFragment
    }
    transactionType: transaction_type
    status
    registeredVehicle: registered_vehicle {
      ...registeredVehicleFragment
    }
    description
    currency
    disputes: transaction_disputes {
      transactionId: transaction_id
      disputeId: dispute_id
      dispute {
        ...disputeFragment
      }
    }
    tags {
      tag: transaction_tag
    }
  }
  ${parkFragment}
  ${registeredVehicleFragment}
  ${disputeFragment}
`;

export interface TransactionDetail {
  id: string;
  index: number;
  addressStreet: string;
  addressCity: string;
  addressState: string;
  park: Park | null;
  doublePark: null;
  transactionType: string;
  status: string;
  registeredVehicle: RegisteredVehicle;
  description: string | null;
  currency: string;
  disputes: { dispute: Dispute }[];
  tags: { tag: TransactionTagValue }[];
}

export type TransactionTagValue = "policy_violation" | "unregistered_vehicle_violation" | "first_park" | "violation";
