import React, { useState } from "react";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { PayerTransactionSummary } from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionSummary/PayerTransactionSummary";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { gql, useQuery } from "@apollo/client";
import {
  PayerTransactionLogDeposit,
  payerTransactionLogDepositFragment,
} from "common/graphql/fragments/payerTransactionLogDepositFragment";
import { DepositLog } from "@app.automotus.io/components/DepositLog";
import {
  GET_PAYMENT_TRANSACTIONS,
  GetPaymentTransactionsData,
  GetPaymentTransactionsVars,
} from "common/graphql/queries/GetPaymentTransactions";
import { TransactionPayments } from "@app.automotus.io/components/TransactionPayments";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import ButtonBase from "@mui/material/ButtonBase";

export const PayerDashboard: React.FC = () => {
  const [section, setSection] = useState("payments");
  const { userProfile, loading: profileLoading, refetch: refetchUserProfile, error: profileError } = useUserProfile();

  const {
    data: depositData,
    loading: depositDataLoading,
    error: depositDataError,
    refetch: refetchDepositData,
  } = useQuery<GetDepositsData>(GET_DEPOSITS);

  const loading = profileLoading || depositDataLoading;

  if (profileError || depositDataError) {
    return (
      <LocalLevelError
        onTryAgain={() => {
          if (profileError) {
            refetchUserProfile();
          }
          if (depositDataError) {
            refetchDepositData();
          }
        }}
      />
    );
  }

  return (
    <>
      <PayerTransactionSummary
        loading={loading}
        accountHolderName={userProfile?.account.holderName || ""}
        digitalWalletBalanceAmount={userProfile?.account.wallets[0].desiredWalletBalance}
        availableBalanceAmount={userProfile?.account.wallets[0]?.status?.balance || 0}
        lastWalletDepositAmount={depositData?.deposits?.length ? +depositData.deposits[0].price : 0}
        lastWalletDepositDate={
          depositData?.deposits?.length ? new Date(depositData.deposits[0].transactionTimestamp) : null
        }
      />
      <Box sx={{ bgcolor: (theme) => theme.palette.grey[100] }}>
        <Container maxWidth={"lg"} sx={{ pb: 14 }}>
          <Stack
            sx={{ py: 2.5 }}
            direction={"row"}
            spacing={2.5}
            divider={
              <Divider
                orientation={"vertical"}
                flexItem
                sx={{ border: 1, borderColor: (theme) => theme.palette.text.primary }}
              />
            }
          >
            <ButtonBase
              sx={{
                typography: "h6",
                fontWeight: (theme) => (section === "payments" ? theme.typography.fontWeightMedium : undefined),
              }}
              onClick={() => setSection("payments")}
            >
              Payments
            </ButtonBase>
            <ButtonBase
              sx={{
                typography: "h6",
                fontWeight: (theme) => (section === "deposits" ? theme.typography.fontWeightMedium : undefined),
              }}
              onClick={() => setSection("deposits")}
            >
              Deposits
            </ButtonBase>
          </Stack>
          {section === "payments" ? <Payments /> : <Deposits />}
        </Container>
      </Box>
    </>
  );
};

const GET_DEPOSITS = gql`
  query GetDeposits {
    deposits: financial_transaction_log(
      where: { transaction_type: { _eq: gateway_transaction } }
      order_by: { transaction_ts: desc }
    ) {
      ...payerTransactionLogDepositFragment
    }
  }
  ${payerTransactionLogDepositFragment}
`;

interface GetDepositsData {
  deposits: PayerTransactionLogDeposit[];
}

const Deposits: React.FC = () => {
  const { data, loading, refetch, error: depositDataError } = useQuery<GetDepositsData>(GET_DEPOSITS);

  const depositsWithBalance = data?.deposits.map((d) => ({
    id: d.transactionId,
    time: new Date(d.transactionTimestamp),
    date: new Date(d.transactionTimestamp),
    balance: d.accountBalance,
    amount: d.price,
    description: "Digital Wallet Refill",
    payment: "...",
  }));

  if (depositDataError) {
    return (
      <LocalLevelError
        onTryAgain={() => {
          refetch();
        }}
      />
    );
  }

  return <DepositLog loading={loading} data={depositsWithBalance} />;
};

const Payments: React.FC = () => {
  const {
    data,
    loading,
    refetch,
    error: paymentTransactionDataError,
  } = useQuery<GetPaymentTransactionsData, GetPaymentTransactionsVars>(GET_PAYMENT_TRANSACTIONS, {
    onError: (err) => {
      console.error(err);
    },
  });

  const rows = (data?.transactions || []).map((t) => {
    return {
      id: t.transactionId,
      date: new Date(t.endTime),
      startTime: new Date(t.startTime),
      endTime: new Date(t.endTime),
      address: t.addressStreet,
      city: `${t.addressCity}, ${t.addressState}`,
      vehicle: `${t.licensePlateStateCode} ${t.licensePlateNumber}`,
      // Price is a negative value in terms of its effect on balance, but we don't want to display it as such to the
      // user.
      amount: -t.price,
      balance: t.accountBalance,
      violation: t.policyViolation,
      status: t.status,
      index: t.index,
      gatewayTransactionType: t.gatewayTransactionType,
    };
  });

  if (paymentTransactionDataError) {
    return (
      <LocalLevelError
        onTryAgain={() => {
          refetch();
        }}
      />
    );
  }

  return <TransactionPayments loading={loading} data={rows} />;
};
