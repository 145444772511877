import React from "react";
import MuiSnackbar, { SnackbarProps as MuiSnackbarProps } from "@mui/material/Snackbar";
import AlertCustom, { AlertCustomProps } from "./AlertCustom";
import useIsMobile from "../hooks/useIsMobile";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

/**
 * This component renders an alert contained within a Snackbar.
 *
 * On mobile screens, the snackbar is displayed in the bottom center of the screen
 * by default. On desktop, the snackbar is anchored to the bottom left of the
 * screen.
 */
export const SnackbarAlert: React.FC<SnackbarAlertProps> = ({
  autoHideDuration = 3000,
  onClose,
  severity,
  message = "",
  anchorOrigin: anchorOriginValue,
  action,
  ...rest
}) => {
  const isMobile = useIsMobile();
  let sx = {};
  const anchorOrigin = anchorOriginValue || (isMobile ? defaultAnchorOrigin.mobile : defaultAnchorOrigin.desktop);
  if (anchorOrigin.vertical === "top") {
    sx = { marginTop: { lg: "88px", xs: "82px" } };
  }

  return (
    <MuiSnackbar
      anchorOrigin={anchorOrigin}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      {...rest}
      sx={{ width: { xs: 290, md: 440 }, margin: "auto", ...sx }}
    >
      {severity !== "black" ? (
        <AlertCustom onClose={onClose} severity={severity} sx={{ width: "100%" }} action={action}>
          {message}
        </AlertCustom>
      ) : (
        <Box
          sx={{
            display: "flex",
            borderRadius: "4px",
            backgroundColor: "#323232",
            p: "6px 16px",
            boxShadow:
              "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
          }}
        >
          <Typography variant="body1" sx={{ fontSize: "14px", color: "white", py: 1, width: "max-content" }}>
            Your request has been sent. An agent will reach out to you shortly.
          </Typography>
        </Box>
      )}
    </MuiSnackbar>
  );
};

const defaultAnchorOrigin = {
  mobile: {
    vertical: "bottom" as const,
    horizontal: "center" as const,
  },
  desktop: {
    vertical: "bottom" as const,
    horizontal: "left" as const,
  },
};

export interface SnackbarAlertProps extends Omit<MuiSnackbarProps, "onClose"> {
  onClose?: (event?: React.SyntheticEvent | Event, reason?: string) => void;
  severity?: AlertCustomProps["severity"] | "black";
}

export default SnackbarAlert;
