import React from "react";
import { EventPolicyComplianceDetail } from "common/graphql";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import formatDate from "date-fns/format";
import times from "lodash/times";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import { formatCurrency } from "common/format";

/**
 * Displays the time and rate breakdown of a park type transaction to a payee user.
 */
export const TransactionTimeAndRateBreakdown: React.FC<TransactionTimeAndRateBreakdownProps> = ({
  loading,
  policyComplianceDetails = [],
  isFreeTransaction = false,
}) => {
  return (
    <Paper>
      <Box sx={{ pt: 3, px: 3, pb: 1.5 }}>
        <Typography variant={"h5"} fontWeight={400}>
          Time and Rate Breakdown
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ height: 227, overflowY: loading ? "none" : "scroll" }}>
        {loading ? (
          <LoadingContents />
        ) : (
          policyComplianceDetails
            // In order to prevent display of rounded down rates where the final rate application results in a price of
            // zero dollars, we filter out those rate applications where the rate is non-zero and the price is zero.
            .filter((pcd) => pcd.rate === 0 || (pcd.rate !== 0 && pcd.price !== 0))
            .map((pcd) => <TimeAndRateRow key={pcd.id} detail={pcd} isFreeTransaction={isFreeTransaction} />)
        )}
      </Box>
    </Paper>
  );
};

const LoadingContents: React.FC = () => (
  <Stack sx={{ p: 2.5 }} spacing={2.5}>
    {times(3, (i) => (
      <Box key={i} display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
        <Skeleton width={221} height={50} />
        <Skeleton width={138} height={50} />
      </Box>
    ))}
  </Stack>
);

const TimeAndRateRow: React.FC<TimeAndRateRowProps> = ({ detail, isFreeTransaction }) => (
  <Box
    sx={{ px: 3, py: 2.5, borderBottom: 1, borderColor: (theme) => theme.palette.divider }}
    display={"flex"}
    flexDirection={"row"}
    justifyContent={"space-between"}
  >
    <Box>
      <Typography variant={"body1"} fontSize={20}>
        {formatDate(new Date(detail.rateStartAt), "hh:mm aa")} - {formatDate(new Date(detail.rateEndAt), "hh:mm aa")}
      </Typography>
    </Box>
    <Box>
      <Typography align="right" variant={"body1"} fontSize={20}>
        {isFreeTransaction
          ? "$0.00 (free)"
          : detail.violation
          ? "Violation"
          : `$${formatCurrency(detail.rate)} per ${
              detail.rateUnit === "minute"
                ? "min"
                : detail.rateUnit === "hour"
                ? "hr"
                : detail.rateUnit === "second"
                ? "sec"
                : detail.rateUnit
            }`}
      </Typography>
    </Box>
  </Box>
);

interface TimeAndRateRowProps {
  detail: EventPolicyComplianceDetail;
  isFreeTransaction: boolean;
}

/** Props passed to initialize a {@link TransactionTimeAndRateBreakdown} component instance */
export interface TransactionTimeAndRateBreakdownProps {
  /** Indicates loading status of component */
  loading?: boolean;
  /** Policy compliance details indicating rate breakdown */
  policyComplianceDetails?: EventPolicyComplianceDetail[];
  isFreeTransaction?: boolean;
}

export default TransactionTimeAndRateBreakdown;
