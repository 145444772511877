import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Header = ({ title, onClose = () => undefined }: { title: string; onClose?: () => void }) => (
  <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ mb: 3 }}>
    <Grid item>
      <Typography sx={{ typography: { xs: "h7", tiny: "h6", md: "h5" } }}>{title}</Typography>
    </Grid>
    <Grid item>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Grid>
  </Grid>
);

export const Content: React.FC<{ title: string; onClose?: () => void }> = ({
  title,
  children,
  onClose = () => undefined,
}) => (
  <Box sx={{ px: 2, py: 4 }}>
    {title && <Header title={title} onClose={onClose} />}
    {children}
  </Box>
);

export default Content;
