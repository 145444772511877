import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { phoneNumberRegex } from "common/constants";
import PhoneNumberInput, { PhoneNumberInputProps } from "@app.automotus.io/components/common/PhoneNumberInput";

/**
 * Component that displays a phone number input and allows a user to submit his
 * phone number in exchange for a confirmation code for passwordless login
 * purposes.
 *
 * Forwards a ref to its submission button
 */
export const PasswordlessPhoneNumberEntry = React.forwardRef<HTMLButtonElement, PasswordlessPhoneNumberEntryProps>(
  (
    {
      phoneNumber = "",
      onChangePhoneNumber = () => undefined,
      onSubmitPhoneNumber = () => undefined,
      invalidPhoneNumberError = false,
      loading = false,
      failedToGetConfirmationCodeError = false,
    },
    ref,
  ) => {
    const textAlign = "left";
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

    const error = failedToGetConfirmationCodeError || invalidPhoneNumberError;

    const errorMessage =
      failedToGetConfirmationCodeError || invalidPhoneNumberError ? "Please enter a valid phone number" : "";

    return (
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <Typography variant={isDesktop ? "h4" : isSmall ? "h6" : "h5"} align={textAlign}>
          Park easy with <span style={{ color: theme.palette.primary.main }}>CurbPass</span>
        </Typography>
        <Typography variant="body2" align={textAlign} sx={{ mb: 3 }}>
          Enter your number and we’ll send a code to get you registered.
        </Typography>
        <Formik
          initialValues={{ phoneNumber }}
          onSubmit={(values) => onSubmitPhoneNumber(values.phoneNumber)}
          validationSchema={yup.object().shape({
            phoneNumber: PhoneNumberInput.validationSchema,
          })}
        >
          {(props) => (
            <Form
              onSubmit={props.handleSubmit}
              onReset={props.handleReset}
              noValidate
              style={{ display: "flex", flex: 1, flexDirection: "column" }}
            >
              <Box sx={{ mb: 3 }}>
                <PhoneNumberInput
                  onChange={onChangePhoneNumber}
                  autoFocus
                  fullWidth
                  name="phoneNumber"
                  disabled={loading}
                />
              </Box>
              {error && (
                <Alert
                  icon={<InfoIcon sx={{ fontSize: "20px", color: "#E31B0C", display: "flex", alignSelf: "center" }} />}
                  severity="error"
                  sx={{ color: "#E31B0C" }}
                >
                  {errorMessage}
                </Alert>
              )}
              <Box sx={{ display: "flex", flex: 1, minHeight: "40px" }} />
              <LoadingButton
                disabled={!props.values.phoneNumber || !phoneNumberRegex.test(props.values.phoneNumber)}
                type="submit"
                ref={ref}
                fullWidth
                variant="contained"
                loading={loading}
                sx={{ textTransform: "unset", px: 2, py: 0.75, fontSize: "1rem" }}
              >
                Get Verification Code
              </LoadingButton>
            </Form>
          )}
        </Formik>
      </Box>
    );
  },
);

/** Props passed to initialize a {@link PasswordlessPhoneNumberEntry} component */
export interface PasswordlessPhoneNumberEntryProps {
  /** Phone number entered by the user. E.164 format is acceptable */
  phoneNumber: string;
  /** Handler fired when phone number changes */
  onChangePhoneNumber?: PhoneNumberInputProps["onChange"];
  /** Handler fired when user submits his phone number */
  onSubmitPhoneNumber?: (phoneNumber: string) => void;
  /** Indicates whether the operation to get the confirmation code is in progress */
  loading?: boolean;
  /** Indicates whether the user attempted to submit an invalid phone number */
  invalidPhoneNumberError?: boolean;
  /** Indicates whether the user failed to receive a confirmation code */
  failedToGetConfirmationCodeError?: boolean;
}

export default PasswordlessPhoneNumberEntry;
