import React, { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransactionImageCarousel from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionImageCarousel";
import {
  GET_TRANSACTION_DETAIL,
  GET_TRANSACTION_IMAGES,
  GetTransactionDetailData,
  GetTransactionDetailVars,
  GetTransactionImagesData,
  GetTransactionImagesVars,
} from "common/graphql";
import { useMutation, useQuery } from "@apollo/client";

/**
 * Displays a header/title component in the detailed transaction display for a payee user.
 */
export const TransactionHeader: React.FC<TransactionHeaderProps> = ({
  transactionId,
  disableImageCarousel = false,
}) => {
  const {
    data: transactionData,
    loading: transactionLoading,
    error: transactionError,
    refetch: refetchTransaction,
  } = useQuery<GetTransactionDetailData, GetTransactionDetailVars>(GET_TRANSACTION_DETAIL, {
    variables: {
      transactionId,
    },
  });

  const transactionTags = transactionData?.transaction?.tags.map(({ tag }) => tag) || [];

  const isViolation = Boolean(transactionTags.find((t) => Boolean(t.match(/violation/))));

  const [getTransactionImages, { data: imagesData, loading: imagesLoading, error: getImagesError }] = useMutation<
    GetTransactionImagesData,
    GetTransactionImagesVars
  >(GET_TRANSACTION_IMAGES, {
    onError(err) {
      console.error("failed to fetch transaction images", err);
    },
  });

  useEffect(() => {
    getTransactionImages({ variables: { transactionId } });
  }, [getTransactionImages, transactionId]);

  const error = getImagesError || transactionError;
  const loading = imagesLoading || transactionLoading;

  return (
    <Paper sx={{ p: 3 }}>
      {error ? (
        <LocalLevelError
          onTryAgain={() => {
            getTransactionImages({ variables: { transactionId } });
            refetchTransaction();
          }}
        />
      ) : (
        <Stack spacing={3}>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography
              variant={"h5"}
              sx={{ flex: 1, overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}
            >
              Transaction ID: {transactionData?.transaction?.index.toString().padStart(6, "0")}
            </Typography>
            {isViolation && (
              <Typography sx={{ color: (theme) => theme.palette.error.main, ml: 2 }} variant={"h7"}>
                Violation
              </Typography>
            )}
          </Box>
          {disableImageCarousel || (
            /* TODO: add computed fields to transactions table to indicate session start, session end in local time */
            <TransactionImageCarousel
              loading={loading}
              height={404}
              parkStartDate={transactionData && new Date(transactionData.transaction?.park?.startTime || 0)}
              parkEndDate={transactionData && new Date(transactionData.transaction?.park?.endTime || 0)}
              licensePlateCaptureDate={transactionData && new Date(transactionData.transaction?.park?.startTime || 0)}
              parkStartImageSrc={imagesData?.transactionImages?.sessionStartImageUrl}
              parkEndImageSrc={imagesData?.transactionImages?.sessionEndImageUrl}
              licensePlateCaptureImageSrc={imagesData?.transactionImages?.licensePlateImageUrl}
            />
          )}
        </Stack>
      )}
    </Paper>
  );
};

export interface TransactionHeaderProps {
  /** ID of the transaction. It is assumed that this is already available prior to loading the transaction */
  transactionId: string;
  /** Disables display of image carousel when set. */
  disableImageCarousel?: boolean;
}

export default TransactionHeader;
