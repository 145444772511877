import formatDate from "date-fns/format";

export function formatHourAmPm(h: number): string {
  return `${h % 12 || 12} ${h < 12 ? "AM" : "PM"}`;
}

export const convertDateToString = (date: Date): string => {
  return formatDate(date, "MM/dd/yyyy");
};

export const convertTimeToString = (date: Date): string => {
  return formatDate(date, "hh:mm a");
};

export const convertDateTimeToString = (date: Date): string => {
  return convertDateToString(date) + " " + convertTimeToString(date);
};
