import { useEffect, useState, useCallback } from "react";
import { CityLocationInfo, cityLocationInfoVar } from "@app.automotus.io/components/scenes/SignUp/reactiveVars";
import { useReactiveVar } from "@apollo/client";
import { findNearestLocation } from "common/helpers";
import { CITY_OPTIONS } from "common/constants";
import { useGeoLocation } from "@app.automotus.io/components/hooks/useGeoLocation";

export function useActivePayee(): UseActivePayeeAccountIdResult {
  const payeeInfo = useReactiveVar(cityLocationInfoVar);
  const { loading, error: locationError, location } = useGeoLocation();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (!payeeInfo && location) {
      const nearestCity = findNearestLocation(CITY_OPTIONS, location);
      if (!nearestCity) {
        setError(new Error("no nearest payee exists"));
        return;
      }
      cityLocationInfoVar({
        payeeAccountId: nearestCity.payeeAccountId,
        cityName: nearestCity.value,
      });
    }
  }, [payeeInfo, location]);

  const setActivePayee = useCallback((newActivePayee: CityLocationInfo) => {
    setError(undefined);
    cityLocationInfoVar(newActivePayee);
  }, []);

  return {
    loading,
    error: error || locationError,
    activePayee: payeeInfo,
    setActivePayee,
  };
}

export interface UseActivePayeeAccountIdResult {
  loading: boolean;
  error?: Error;
  activePayee: CityLocationInfo | null;
  setActivePayee: (newActivePayee: CityLocationInfo) => void;
}

export default useActivePayee;
