import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useFormikContext } from "formik";
import { VehicleInfoFormSubmitAction, VehicleInfoFormValues, VehicleInfoFormVariant } from "./VehicleInfoForm";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const defaultButtonTexts = {
  addVehicle: "Add Vehicle",
  addAnotherVehicle: "Save and Add Another Vehicle",
  update: "Update Vehicle Information",
  remove: "Remove Vehicle",
  cancel: "Cancel",
};

export const VehicleInfoFormButtons: React.FC<VehicleInfoFormButtonsProps> = ({
  loading,
  disabled,
  variant,
  buttonTexts,
  allowCancelAndFinish = false,
  allowAddAnotherVehicle = true,
  onSaveAndContinue = () => undefined,
}) => {
  const { values, setFieldValue, isSubmitting } = useFormikContext<VehicleInfoFormValues>();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFieldValue("submitAction", event.currentTarget.id as VehicleInfoFormSubmitAction, false);
  };

  const sharedButtonProps = {
    disabled,
    fullWidth: true,
    onClick: handleClick,
    type: "submit" as const,
  };

  if (variant === "add") {
    return loading ? (
      <>
        <Skeleton width="100%" sx={{ mb: 2 }}>
          <LoadingButton>Loading</LoadingButton>
        </Skeleton>
        <Skeleton width="100%">
          <LoadingButton>Loading</LoadingButton>
        </Skeleton>
      </>
    ) : (
      <Stack direction={"column"} spacing={2}>
        <LoadingButton
          {...sharedButtonProps}
          id={"save" as const}
          variant="contained"
          loading={isSubmitting && values.submitAction === "save"}
          sx={{ textTransform: "none", fontSize: { xs: "14px", tiny: "16px", lg: "18px" } }}
        >
          {buttonTexts?.addVehicle || defaultButtonTexts.addVehicle}
        </LoadingButton>
        <LoadingButton
          {...sharedButtonProps}
          id={"add" as const}
          variant="outlined"
          loading={isSubmitting && values.submitAction === "add"}
          disabled={!allowAddAnotherVehicle}
          sx={{ textTransform: "none", fontSize: { xs: "14px", tiny: "16px", lg: "18px" } }}
        >
          {buttonTexts?.addAnotherVehicle || defaultButtonTexts.addAnotherVehicle}
        </LoadingButton>
        {allowCancelAndFinish && (
          <Button
            {...sharedButtonProps}
            variant="outlined"
            type="button"
            onClick={onSaveAndContinue}
            sx={{ textTransform: "none", fontSize: { xs: "14px", tiny: "16px", lg: "18px" } }}
          >
            Cancel and Continue
          </Button>
        )}
      </Stack>
    );
  }

  if (variant === "update") {
    return loading ? (
      <Skeleton width="100%">
        <LoadingButton>Loading</LoadingButton>
      </Skeleton>
    ) : (
      <LoadingButton {...sharedButtonProps} id={"update" as const} variant="contained" loading={isSubmitting}>
        {buttonTexts?.update || defaultButtonTexts.update}
      </LoadingButton>
    );
  }

  return loading ? (
    <Skeleton width="100%">
      <LoadingButton>Loading</LoadingButton>
    </Skeleton>
  ) : (
    <>
      <LoadingButton
        {...sharedButtonProps}
        id={"remove" as const}
        sx={{ mb: 2 }}
        variant="contained"
        loading={isSubmitting && values.submitAction === "remove"}
      >
        {buttonTexts?.remove || defaultButtonTexts.remove}
      </LoadingButton>
      <LoadingButton {...sharedButtonProps} id={"cancel" as const} variant="outlined">
        {buttonTexts?.cancel || defaultButtonTexts.cancel}
      </LoadingButton>
    </>
  );
};

export interface VehicleInfoFormButtonsProps {
  loading: boolean;
  disabled?: boolean;
  variant: VehicleInfoFormVariant;
  allowCancelAndFinish?: boolean;
  allowAddAnotherVehicle?: boolean;
  onSaveAndContinue?: () => void;
  buttonTexts?: {
    addVehicle?: string;
    addAnotherVehicle?: string;
    update?: string;
    remove?: string;
    cancel?: string;
  };
}

export default VehicleInfoFormButtons;
