import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ParkingRatesTabContent from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal/ParkingRatesTabContent";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import ParkingHoursTabContent from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal/ParkingHoursTabContent";
import { useQuery } from "@apollo/client";
import {
  GET_PARKING_RATES_FOR_PAYEE,
  GET_ZONES_WITH_PARKING_HOURS,
  GetParkingRatesForPayeeData,
  GetParkingRatesForPayeeVars,
  GetZonesWithParkingHoursData,
  GetZonesWithParkingHoursVars,
} from "common/graphql";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";

export const PartnerLandingPageParkingRatesAndHoursModal: React.FC<PartnerLandingPageParkingRatesParkingHoursModalProps> =
  ({ open, onClose, payeeAccountId }) => {
    const [tabValue, setTabValue] = useState<"rates" | "hours">("rates");

    const handleTabChange = (event: React.SyntheticEvent, newValue: "rates" | "hours") => {
      setTabValue(newValue);
    };

    const {
      data: parkingHoursData,
      refetch: refetchParkingHours,
      error: parkingHoursError,
    } = useQuery<GetZonesWithParkingHoursData, GetZonesWithParkingHoursVars>(GET_ZONES_WITH_PARKING_HOURS, {
      variables: { payeeAccountId },
      onError: (err) => {
        console.error(err);
      },
    });

    const {
      data: parkingRatesData,
      refetch: refetchParkingRates,
      error: parkingRatesError,
    } = useQuery<GetParkingRatesForPayeeData, GetParkingRatesForPayeeVars>(GET_PARKING_RATES_FOR_PAYEE, {
      variables: { payeeAccountId },
      onError: (err) => {
        console.error(err);
      },
    });

    const err = parkingRatesError || parkingHoursError;

    const refetchOnError = () => {
      if (parkingHoursError) {
        refetchParkingHours();
      }
      if (parkingRatesError) {
        refetchParkingRates();
      }
    };

    let parkingRates: ParkingRates = {
      currency: "usd",
      intervals: [],
    };
    let parkingHours: ParkingHourIntervalDescriptor[] = [];
    if (parkingRatesData) {
      const ratesData = parkingRatesData.payee_parking_rate;
      if (ratesData && ratesData.length > 0) {
        parkingRates = {
          currency: ratesData[0].currency,
          intervals: ratesData.map(
            ({ rate: pricePerMinute, start_duration: startMinutes, end_duration: endMinutes }) => ({
              startMinutes,
              endMinutes,
              pricePerMinute,
            }),
          ) as ParkingRateInterval[],
        };
      }
    }
    let daysOfWeek = ["Mon", "Fri"];
    if (parkingHoursData) {
      const { zones: parkingZones } = parkingHoursData;
      if (parkingZones && parkingZones.length > 0 && parkingZones[0].zone_policy_time_spans.length > 0) {
        daysOfWeek = parkingZones[0].zone_policy_time_spans[0].time_span.days_of_week.map(
          (day) => day.charAt(0).toUpperCase() + day.slice(1),
        );
      }
      parkingHours = parkingZones
        ?.map(({ name: location, zone_policy_time_spans: time_spans }) => {
          if (time_spans && time_spans.length > 0 && time_spans[0].time_span) {
            const {
              time_span: { time_of_day_start_hours: startHour, time_of_day_end_hours: endHour },
            } = time_spans[0];

            return {
              startHour,
              endHour,
              location,
            };
          }
          return null;
        })
        .filter((item) => item) as ParkingHourIntervalDescriptor[];
    }

    return (
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            borderRadius: 2,
            minWidth: 295,
            minHeight: 340,
            height: 340,
            overflow: "hidden!important" as "hidden",
          },
        }}
        onBackdropClick={onClose}
      >
        <TabContext value={tabValue}>
          <Box>
            <TabList onChange={handleTabChange} variant={"fullWidth"}>
              <Tab
                label={"Parking Rates"}
                value={"rates"}
                sx={{ fontSize: "18px", fontWeight: 500, textTransform: "none" }}
              />
              <Tab
                label={"Parking Hours"}
                value={"hours"}
                sx={{ fontSize: "18px", fontWeight: 500, textTransform: "none" }}
              />
            </TabList>
          </Box>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"space-between"}
            sx={{
              height: "100%",
              p: 1.5,
            }}
          >
            {err ? (
              <LocalLevelError onTryAgain={refetchOnError} />
            ) : (
              <>
                <TabPanel
                  value={"rates"}
                  sx={{
                    p: 0,
                  }}
                >
                  <ParkingRatesTabContent parkingRates={parkingRates} />
                </TabPanel>
                <TabPanel value={"hours"} sx={{ p: 0 }}>
                  <ParkingHoursTabContent parkingHours={parkingHours} daysOfWeek={daysOfWeek} />
                </TabPanel>
                {tabValue === "hours" && <Divider sx={{ width: "150%", ml: "-20%" }} />}
              </>
            )}
            <Button
              variant={"contained"}
              fullWidth
              onClick={onClose}
              sx={{
                mt: 1.25,
                mb: 0,
                textTransform: "none",
                height: { xs: "30px", tiny: "42px" },
                fontSize: { xs: "14px", tiny: "18px" },
                fontWeight: { xs: 600, tiny: 400 },
              }}
            >
              Close
            </Button>
          </Box>
        </TabContext>
      </Dialog>
    );
  };

export interface PartnerLandingPageParkingRatesParkingHoursModalProps {
  open: boolean;
  onClose: () => void;
  payeeAccountId: string;
}

export interface ParkingRates {
  currency: string;
  intervals: ParkingRateInterval[];
}

export interface ParkingRateInterval {
  startMinutes: number;
  endMinutes: number;
  pricePerMinute: number;
}

export interface ParkingHourIntervalDescriptor {
  startDay: number;
  endDay: number;
  startHour: number;
  endHour: number;
  location: string;
}

export default PartnerLandingPageParkingRatesAndHoursModal;
