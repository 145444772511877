import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { ParkingRates } from "../PartnerLandingPageMobileNavbar";

import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import {
  GetZonesWithParkingHoursData,
  GetZonesWithParkingHoursVars,
  GET_ZONES_WITH_PARKING_HOURS,
  GetParkingRatesForPayeeData,
  GetParkingRatesForPayeeVars,
  GET_PARKING_RATES_FOR_PAYEE,
} from "common/graphql";
import { useQuery } from "@apollo/client";
import { formatCurrency, formatHourAmPm } from "common/format";
import { ParkingHourIntervalDescriptor, ParkingRateInterval } from "../PartnerLandingPageParkingRatesAndHoursModal";

export const MobileZoneRegulations: React.FC<MobileZoneRegulationsProps> = ({ payeeAccountId }) => {
  const theme = useTheme();

  const {
    data: parkingHoursData,
    refetch: refetchParkingHours,
    error: parkingHoursError,
  } = useQuery<GetZonesWithParkingHoursData, GetZonesWithParkingHoursVars>(GET_ZONES_WITH_PARKING_HOURS, {
    variables: { payeeAccountId },
    onError: (err) => {
      console.error(err);
    },
  });

  const {
    data: parkingRatesData,
    refetch: refetchParkingRates,
    error: parkingRatesError,
  } = useQuery<GetParkingRatesForPayeeData, GetParkingRatesForPayeeVars>(GET_PARKING_RATES_FOR_PAYEE, {
    variables: { payeeAccountId },
    onError: (err) => {
      console.error(err);
    },
  });

  const refetchOnError = () => {
    if (parkingHoursError) {
      refetchParkingHours();
    }
    if (parkingRatesError) {
      refetchParkingRates();
    }
  };

  if (parkingHoursError || parkingRatesError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  let parkingRates: ParkingRates = {
    currency: "usd",
    intervals: [],
  };
  let parkingHours: ParkingHourIntervalDescriptor[] = [];
  if (parkingRatesData) {
    const ratesData = parkingRatesData.payee_parking_rate;
    if (ratesData && ratesData.length > 0) {
      parkingRates = {
        currency: ratesData[0].currency,
        intervals: ratesData.map(
          ({ rate: pricePerMinute, start_duration: startMinutes, end_duration: endMinutes }) => ({
            startMinutes,
            endMinutes,
            pricePerMinute,
          }),
        ) as ParkingRateInterval[],
      };
    }
  }
  let daysOfWeek = ["Mon", "Fri"];
  if (parkingHoursData) {
    const { zones: parkingZones } = parkingHoursData;
    if (parkingZones && parkingZones.length > 0 && parkingZones[0].zone_policy_time_spans.length > 0) {
      daysOfWeek = parkingZones[0].zone_policy_time_spans[0].time_span.days_of_week.map(
        (day) => day.charAt(0).toUpperCase() + day.slice(1),
      );
    }
    parkingHours = parkingZones
      ?.map(({ name: location, zone_policy_time_spans: time_spans }) => {
        if (time_spans && time_spans.length > 0 && time_spans[0].time_span) {
          const {
            time_span: { time_of_day_start_hours: startHour, time_of_day_end_hours: endHour },
          } = time_spans[0];

          return {
            startHour,
            endHour,
            location,
          };
        }
        return null;
      })
      .filter((item) => item) as ParkingHourIntervalDescriptor[];
  }

  return (
    <>
      {parkingHours.map(({ startHour, endHour, location }) => (
        <Box key={location}>
          <Box px={2} py={0.5} sx={{ background: "rgba(13, 85, 191, 0.08)" }}>
            <Typography variant={"caption"} color={theme.palette.text.secondary}>
              {location}
            </Typography>
            <Typography variant={"body1"} fontWeight={500}>
              {daysOfWeek[0]} - {daysOfWeek[daysOfWeek.length - 1]} | {formatHourAmPm(startHour)} to{" "}
              {formatHourAmPm(endHour)}
            </Typography>
          </Box>
          <Box px={2} py={1}>
            <Box sx={{ display: "flex", justifyContent: "space-between", pb: 1 }}>
              <Typography variant={"caption"} color={theme.palette.text.secondary}>
                Duration
              </Typography>
              <Typography variant={"caption"} color={theme.palette.text.secondary} sx={{ float: "right" }}>
                Amount
              </Typography>
            </Box>
            {parkingRates.intervals.map(({ startMinutes, endMinutes }, index) => {
              const uptoPrice = parkingRates.intervals
                .slice(0, index + 1)
                .reduce((acc, cur) => acc + cur.pricePerMinute * (cur.endMinutes - cur.startMinutes), 0);
              return (
                <Box
                  key={`${startMinutes}-${endMinutes}`}
                  display="flex"
                  justifyContent="space-between"
                  sx={{ py: 0.5 }}
                >
                  <Box>
                    <Typography variant={"body1"}>
                      {startMinutes} - {endMinutes} mins
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent={"flex-end"}>
                    <Typography align="right" variant={"body1"} sx={{ fontWeight: 500 }}>
                      upto ${formatCurrency(uptoPrice)}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      ))}
    </>
  );
};

export interface MobileZoneRegulationsProps {
  payeeAccountId: string;
}
export default MobileZoneRegulations;
