import React, { useImperativeHandle, useRef, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";
import DigitalWalletSetup, {
  DigitalWalletSetupProps,
} from "@app.automotus.io/components/scenes/SignUp/DigitalWalletSetup";

/** Route for /signup/payment */
export const Payment: React.ForwardRefRenderFunction<
  { scrollIntoPayButton: () => void },
  { isTryingForFree: boolean; isValid: boolean; invoiceId?: string }
> = ({ isValid, isTryingForFree, invoiceId }, ref) => {
  const walletRef = useRef<{ scrollIntoPayButton: () => void }>(null);
  const [searchParams] = useSearchParams();
  const defaultWalletValue = extractWalletValueFromSearchParams(searchParams);
  const canceled = !!searchParams.get("canceled");

  const digitalWalletSetupProps: DigitalWalletSetupProps = {
    canceled,
    isTryingForFree,
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        scrollIntoPayButton() {
          if (walletRef?.current) walletRef.current.scrollIntoPayButton();
        },
      };
    },
    [],
  );

  if (defaultWalletValue) {
    digitalWalletSetupProps.defaultWalletValue = defaultWalletValue;
  }

  return (
    <DigitalWalletSetup
      isTryingForFree={isTryingForFree}
      ref={walletRef}
      canceled={canceled}
      isValid={isValid}
      invoiceId={invoiceId}
    />
  );
};

function extractWalletValueFromSearchParams(searchParams: URLSearchParams): number | null {
  const asString = searchParams.get("walletBalance") || "";

  if (!asString) {
    return null;
  }

  const asNumber = Number(asString);
  if (!asNumber) {
    return null;
  }

  return asNumber;
}

export default forwardRef(Payment);
