import React from "react";
import { TextField, TextFieldProps } from "formik-mui";
import { useField, useFormikContext } from "formik";
import * as yup from "yup";

export const LicensePlateField = Object.assign(
  ({
    name = "licensePlate",
    id = "license-plate",
    label = "License Plate",
    placeholder = "License Plate*",
    required = true,
    fullWidth = true,
    ...rest
  }: LicensePlateFieldProps) => {
    const form = useFormikContext();
    const [field, meta, { setValue }] = useField(name);

    const handleChange = React.useCallback(
      (event) => {
        setValue(event.target.value.toUpperCase().replace(/\s/g, ""));
      },
      [setValue],
    );

    return (
      <TextField
        {...rest}
        {...{ id, label, placeholder, required, fullWidth }}
        meta={meta}
        form={form}
        field={{
          ...field,
          onChange: handleChange,
        }}
        inputProps={{ maxLength: 7 }}
      />
    );
  },
  {
    defaultInitialValue: "",
    validationSchema: yup
      .string()
      .required("Valid license plate required")
      .max(7, "7 character maximum")
      .matches(/^[A-Za-z0-9]{3,7}$/, "Valid license plate required"),
  },
);

export interface LicensePlateFieldProps extends Omit<TextFieldProps, "meta" | "form" | "field"> {
  name?: string;
  value?: string;
}

export default LicensePlateField;
