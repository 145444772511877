import { createTheme } from "@mui/material/styles";

const fontFamily = ['"Nunito Sans"', '"Helvetica"', '"Arial"', "sans-serif"].join(",");

export const themePrimary = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      tiny: 360,
      sm: 450,
      md: 700,
      lg: 1024,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#0D55BF",
      white: "#FFFFFF",
      black: "#000000",
    },
    error: {
      main: "#E31B0C",
    },
  },
  typography: {
    fontFamily,
    fontWeightMedium: 600,
    h1: {
      fontFamily,
      fontSize: "6rem",
    },
    h2: {
      fontFamily,
      fontSize: "3.75rem",
    },
    h3: {
      fontFamily,
      fontSize: "3rem",
    },
    h4: {
      fontFamily,
      fontSize: "2.125rem",
    },
    h5: {
      fontFamily,
      fontSize: "1.75rem",
      fontWeight: 300,
    },
    h6: {
      fontFamily,
      fontWeight: 300,
      fontSize: "1.5rem",
    },
    h7: {
      fontFamily,
      fontSize: "1.25rem",
      fontWeight: 400,
    },
    subtitle1: {
      fontFamily,
      fontSize: "1rem",
    },
    body1: {
      fontFamily,
      fontSize: "1rem",
    },
    body2: {
      fontFamily,
      fontSize: "1rem",
    },
    body3: {
      fontFamily,
      fontWeight: 600,
      fontSize: "1rem",
    },
    body4: {
      fontFamily,
      fontSize: "0.875rem",
    },
    tooltip: {
      fontFamily,
      fontSize: "0.625rem",
    },
  },
});

/** Allow for new material Typography variants */
declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    tiny: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
  }

  interface SimplePaletteColorOptions {
    white?: string;
    black?: string;
  }
  interface PaletteColor {
    white?: string;
    black?: string;
  }

  interface TypographyVariants {
    h7: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
    tooltip: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
    tooltip?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    body3: true;
    body4: true;
    tooltip: true;
  }
}
