import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import parkIconSvg from "./ParkIcon.svg";
import setupIconSvg from "./SetupIcon.svg";
import cameraIconSvg from "./CameraIcon.svg";
import { styled } from "@mui/material/styles";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

export const PartnerLandingPageHowItWorks: React.FC = () => {
  const StyledImg = styled("img")({});

  const StepItem = ({ iconSrc, description }: { iconSrc: string; description: string }) => (
    <Grid item container columnSpacing={3} justifyContent="flexStart" alignItems="center" flexWrap={"nowrap"}>
      <Grid item>
        <StyledImg src={iconSrc} />
      </Grid>
      <Grid item>
        <Typography variant={"body1"}>{description}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Box display={"flex"} flexDirection={"column"} px={{ xs: 2, tiny: 3, md: 4 }} pt={{ xs: 1.5, tiny: 1.5, md: 4 }}>
      <Box sx={{ mb: { xs: 2, md: 4 }, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography sx={{ typography: { xs: "h6", tiny: "h5", sm: "h4" } }}>How it Works</Typography>
      </Box>
      <Grid container rowSpacing={{ xs: 2.5, tiny: 5, md: 7.5 }}>
        <StepItem
          iconSrc={setupIconSvg}
          description="Register your vehicle and set up a CurbPass using a card, Apple Pay or Google Pay"
        />
        <StepItem
          iconSrc={parkIconSvg}
          description="Get instant access to every Smart Loading Zone without logging in every time you park"
        />
        <StepItem
          iconSrc={cameraIconSvg}
          description="Cameras read your license plate and auto charge your account only for the time you use"
        />
        <Grid item container alignItems="center" flexWrap={"nowrap"}>
          <Link
            variant="body1"
            underline={"hover"}
            component={"a"}
            target={"_blank"}
            onClick={() => window.open("https://www.automotus.co/smart-loading-zones-drivers", "_blank")}
            sx={{ mx: "auto", cursor: "pointer" }}
          >
            Learn More
          </Link>
        </Grid>
        <Box
          sx={{
            width: "100%",
            typography: "body1",
            mt: 1.5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            component={RouterLink}
            to="/terms-of-service"
            state={{ sourceRoute: location.pathname, isFromLandingPage: true }}
            sx={{ cursor: "pointer" }}
          >
            Terms of Service
          </Link>
        </Box>
      </Grid>
    </Box>
  );
};

export default PartnerLandingPageHowItWorks;
