import React from "react";
import SnackPackContext, {
  DEFAULT_SNACK_PACK_CONTEXT,
  ISnackPackContext,
} from "@app.automotus.io/components/context/SnackPack/SnackPackContext";
import { SnackbarOrigin } from "@mui/material/Snackbar";
import { AlertColor } from "@mui/material/Alert";

export interface SnackbarMsg {
  severity: AlertColor;
  message: string;
  anchorOrigin: SnackbarOrigin;
}

export const SNACKBAR_MSGS = {
  VEHICLE_ADDED_SUCCESS: {
    severity: "success",
    message: "Your vehicle has been added.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_ADDED_FAILURE: {
    severity: "error",
    message: "Failed to add vehicle. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_ALREADY_EXISTS: {
    severity: "error",
    message:
      "This vehicle is already registered with an account. Please contact our support team if this does not sound right.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_ALREADY_REGISTERED: {
    severity: "error",
    message: "Vehicle already registered to this account.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_UPDATED_SUCCESS: {
    severity: "success",
    message: "Your vehicle has been updated.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_UPDATED_FAILURE: {
    severity: "error",
    message: "Failed to update vehicle. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_REMOVED_SUCCESS: {
    severity: "success",
    message: "Your vehicle has been removed.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  VEHICLE_REMOVED_FAILURE: {
    severity: "error",
    message: "Failed to remove vehicle. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  DIGITAL_WALLET_CREATED_SUCCESS: {
    severity: "success",
    message: "Your payment was successful",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  PAYMENT_SUCCESS: {
    severity: "success",
    message: "Payment Successful",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  PAYMENT_FAILED: {
    severity: "error",
    message: "Your payment failed to process. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  SOMETHING_WENT_WRONG: {
    severity: "error",
    message: "Something went wrong. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  PAYMENT_DECLINED: {
    severity: "error",
    message: "Your payment method was declined. Please contact your bank or try another method.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  SOMETHING_WENT_WRONG_WITH_APPLE_PAY: {
    severity: "error",
    message: "Something went wrong with Apple Pay. Please try again or try another method.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  SOMETHING_WENT_WRONG_WITH_GOOGLE_PAY: {
    severity: "error",
    message: "Something went wrong with Google Pay. Please try again or try another method.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  PAYMENT_METHOD_SETUP_FAILED: {
    severity: "error",
    message: "Your payment method could not be successfully set up. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  FEEDBACK_NOTIFICATION_SUCCESS: {
    severity: "info",
    message: "A new unpaid invoice has been linked to your account.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  FEEDBACK_NOTIFICATION_FAILED: {
    severity: "error",
    message: "Vehicle Information was not saved.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  CURBPASS_SETTINGS_UPDATE_SUCCESS: {
    severity: "success",
    message: "Your CurbPass settings have been updated.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  CURBPASS_SETTINGS_UPDATE_FAILED: {
    severity: "error",
    message: "Your CurbPass settings could not be saved. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  INVOICE_SEARCH_ERROR_ALREADY_PAID: {
    severity: "error",
    message: "This invoice has already been paid.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  INVOICE_SEARCH_ERROR_ALREADY_REGISTERED: {
    severity: "error",
    message: "This invoice has been claimed by another account. Try logging in.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  INVOICE_SEARCH_ERROR_NOT_FOUND: {
    severity: "error",
    message: "Your invoice number, license plate, and/or state do not match our records. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
  INVOICE_SEARCH_ERROR_GENERIC: {
    severity: "error",
    message: "We could not fetch that invoice. Please try again.",
    anchorOrigin: { vertical: "top", horizontal: "center" },
  },
} as const;

/**
 * Returns the {@link SnackPackContext} injected by a SnackPackProvider.
 * Throws an error if used outside of a SnackPackProvider tree.
 */
export const useSnackPackContext = (): ISnackPackContext => {
  const context = React.useContext(SnackPackContext);

  if (context === DEFAULT_SNACK_PACK_CONTEXT) {
    throw new Error("useSnackPackContext was used outside of a SnackPackProvider");
  }

  return context;
};

export default useSnackPackContext;
