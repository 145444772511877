import React from "react";
import { useQuery } from "@apollo/client";
import {
  EventPolicyComplianceDetail,
  GET_TRANSACTION_DETAIL,
  GetTransactionDetailData,
  GetTransactionDetailVars,
} from "common/graphql";
import Stack from "@mui/material/Stack";
import SessionDetails from "./SessionDetails";
import TransactionTimeAndRateBreakdown from "./TransactionTimeAndRateBreakdown";
import TransactionTotal from "./TransactionTotal";
import sumBy from "lodash/sumBy";
import differenceInSeconds from "date-fns/differenceInSeconds";
import { LocalLevelError } from "@app.automotus.io/components/common/LocalLevelError";

export interface TransactionDetailProps {
  /** ID of the transaction to display */
  transactionId: string;
}
export const TransactionDetail: React.FC<TransactionDetailProps> = ({ transactionId }) => {
  const { data, loading, error, refetch } = useQuery<GetTransactionDetailData, GetTransactionDetailVars>(
    GET_TRANSACTION_DETAIL,
    {
      variables: {
        transactionId,
      },
    },
  );

  const isFreeTransaction = data?.transaction?.tags.some(({ tag }) => tag === "first_park");

  if (error) {
    return <LocalLevelError onTryAgain={() => refetch()} />;
  }

  return (
    <Stack spacing={3}>
      {/* TODO: add computed fields to transactions table to indicate session start, session end in local time */}
      <SessionDetails
        loading={loading}
        addressCity={data?.transaction?.addressCity}
        addressState={data?.transaction?.addressState}
        addressStreet={data?.transaction?.addressStreet}
        startTime={data && new Date(data?.transaction?.park?.startTime || 0)}
        endTime={data && new Date(data?.transaction?.park?.endTime || 0)}
        licensePlateNumber={(data && data?.transaction?.registeredVehicle?.licensePlate) || ""}
        licensePlateStateCode={(data && data?.transaction?.registeredVehicle?.stateCode) || ""}
      />
      <TransactionTimeAndRateBreakdown
        loading={loading}
        isFreeTransaction={isFreeTransaction}
        policyComplianceDetails={data?.transaction?.park?.policyRateDetails?.reduce(
          (acc, { details }) => [
            ...acc,
            ...details.map((d) => ({
              ...d,
              // At present, the start and end times of each rate description are cast
              // to an incorrect timezone. Correct this by adding an offset. Note this
              // will only work for US eastern time.
              // TODO: use new computed fields to populate these values
              rateStartAt: new Date(d.rateStartAt.replace("+00:00", "")).toISOString(),
              rateEndAt: new Date(d.rateEndAt.replace("+00:00", "")).toISOString(),
            })),
          ],
          [] as EventPolicyComplianceDetail[],
        )}
      />
      <TransactionTotal
        loading={loading}
        isFreeTransaction={isFreeTransaction}
        amountBilled={sumBy(data?.transaction?.park?.policyRateDetails || [], "price")}
        sessionDurationSeconds={
          data?.transaction?.park
            ? differenceInSeconds(
                new Date(data.transaction.park?.endTime || 0),
                new Date(data.transaction.park?.startTime || 0),
              )
            : 0
        }
        currencySymbol={"$"}
      />
    </Stack>
  );
};

export default TransactionDetail;
