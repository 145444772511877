import React from "react";
import { ParkingRates } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal/PartnerLandingPageParkingRatesAndHoursModal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { formatCurrency } from "common/format";

export const ParkingRatesTabContent: React.FC<PartnerLandingPageParkingRatesModalContentProps> = ({ parkingRates }) => {
  const { intervals } = parkingRates;
  const theme = useTheme();

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"caption"} color={theme.palette.text.secondary}>
          Duration
        </Typography>
        <Typography variant={"caption"} color={theme.palette.text.secondary} sx={{ float: "right" }}>
          Amount
        </Typography>
      </Box>
      {intervals.map(({ startMinutes, endMinutes }, index) => {
        const uptoPrice = intervals
          .slice(0, index + 1)
          .reduce((acc, cur) => acc + cur.pricePerMinute * (cur.endMinutes - cur.startMinutes), 0);
        return (
          <Box key={`${startMinutes}-${endMinutes}`} display="flex" justifyContent="space-between" sx={{ py: 0.75 }}>
            <Box>
              <Typography variant={"body1"}>
                {startMinutes} - {endMinutes} mins
              </Typography>
            </Box>
            <Box display="flex" justifyContent={"flex-end"}>
              <Typography align="right" variant={"body1"} sx={{ fontWeight: 500 }}>
                upto ${formatCurrency(uptoPrice)}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export interface PartnerLandingPageParkingRatesModalContentProps {
  parkingRates: ParkingRates;
}

export default ParkingRatesTabContent;
