import React from "react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { formatCurrency } from "common/format";
import Typography from "@mui/material/Typography";

/**
 * Component that renders a select amount view.
 */
export const AmountComponent: React.FC<AmountComponentProps> = ({ amount, description, isSelected, onSelect }) => {
  const theme = useTheme();

  return (
    <Box
      id={amount ? `amount-${amount}}` : "try-for-free"}
      sx={{
        width: "100%",
        height: "49px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid",
        borderColor: theme.palette.primary.main,
        boxSizing: "border-box",
        borderRadius: 1,
        textAlign: "center",
        cursor: "pointer",
        p: 0.5,
        ...(isSelected && { backgroundColor: "rgba(13, 85, 191, 0.08)" }),
      }}
      onClick={onSelect}
    >
      <Typography variant="body3" sx={{ fontSize: "14px", color: theme.palette.primary.main }}>
        {amount ? `$${formatCurrency(amount, 0)}` : "Try for Free"}
      </Typography>

      <Typography color={theme.palette.text.secondary} sx={{ fontSize: "10px", fontWeight: 400 }}>
        {description}
      </Typography>
    </Box>
  );
};

export interface AmountComponentProps {
  /** Price in cents, free tier has zero value. */
  amount: number;
  /** Helper text which tells the number of parks */
  description: string;
  /** Determins if the component is selected */
  isSelected: boolean;
  /** Event handler when the component gets selected */
  onSelect: () => void;
}

export default AmountComponent;
