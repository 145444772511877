import { gql } from "@apollo/client";

export const GET_PAYEE_TRANSACTIONS_PRICE_FILTER = { price: { _neq: 0 } };
export const GET_PAYEE_TRANSACTIONS_POTENTIAL_REVENUE_FILTER = { potential_revenue: { _neq: 0 } };

export const GET_PAYEE_TRANSACTIONS_FOR_DAY = gql`
  query GetPayeeTransactionsForDay(
    $day: date!
    $transactionType: String!
    $priceFilter: financial_transaction_log_bool_exp!
  ) {
    transactions: financial_transaction_log(
      where: {
        transaction_date: { _eq: $day }
        transaction_type: { _eq: $transactionType }
        _or: [{ first_park: { _eq: true } }, $priceFilter]
      }
    ) {
      transactionId: transaction_id
      firstPark: first_park
      unregisteredVehicleViolation: unregistered_vehicle_violation
      policyViolation: policy_violation
      transactionType: transaction_type
      eventType: event_type
      potentialRevenue: potential_revenue
      price
      revenue
      currency
      addressCity: address_city
      addressState: address_state
      addressStreet: address_street
      startTime: start_time
      endTime: end_time
      transactionDate: transaction_date
      transactionTs: transaction_ts
      transaction {
        gatewayTransactions: gateway_transactions(limit: 1) {
          gatewayTransactionType: gateway_transaction_type
        }
      }
    }
  }
`;

export interface GetPayeeTransactionsForDayData {
  transactions: PayeeTransactionLogEntry[];
}

export interface PayeeTransactionLogEntry {
  transactionId: string;
  firstPark: boolean;
  unregisteredVehicleViolation: boolean;
  policyViolation: boolean;
  transactionType: "park" | "gateway_transaction";
  eventType: "park" | "double_park" | "gateway_transaction";
  potentialRevenue: number;
  price: number;
  revenue: number;
  currency: string;
  addressCity: string | null;
  addressState: string | null;
  addressStreet: string | null;
  startTime: string | null;
  endTime: string | null;
  transactionTs: string;
  transactionDate: string;
  transaction: {
    gatewayTransactions: {
      gatewayTransactionType: "initial_wallet_load" | "wallet_reload" | "payout" | "wallet_refund";
    }[];
  };
}

export interface GetPayeeTransactionsForDayVars {
  /** The day to fetch transactions for, in yyyy-mm-dd format */
  day: string;
  transactionType: "park" | "gateway_transaction";
  priceFilter: typeof GET_PAYEE_TRANSACTIONS_PRICE_FILTER | typeof GET_PAYEE_TRANSACTIONS_POTENTIAL_REVENUE_FILTER;
}
