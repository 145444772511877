import React from "react";
import Typography, { TypographyProps } from "@mui/material/Typography";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";

export interface LoadingTypographyProps {
  loading?: boolean;
}

export function LoadingTypography<C extends React.ElementType>({
  loading = false,
  children,
  ...rest
}: LoadingTypographyProps & TypographyProps<C, { component?: C }>): React.ReactElement {
  return (
    <LoadingSkeleton loading={loading} width="100%" height="100%">
      <Typography component="p" {...rest}>
        {children}
      </Typography>
    </LoadingSkeleton>
  );
}

export default LoadingTypography;
