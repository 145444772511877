import React from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import NeedHelpButton from "../../NeedHelpButton";
import Grid from "@mui/material/Grid";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import { SavedPaymentCard } from "@app.automotus.io/components/SavedPaymentMethod/SavedPaymentCard";
import { LoadingTypography } from "@app.automotus.io/components/LoadingTypography/LoadingTypography";
import { PaymentMethod } from "common/graphql";
import { appName } from "common/constants";

const SavedDigitalWalletTitle = ({ loading = false }: { loading?: boolean }) => {
  return (
    <LoadingTypography loading={loading} variant="h5" sx={{ mt: 2 }}>
      Saved Digital Wallet
    </LoadingTypography>
  );
};

const SavedDigitalWalletBalance = ({
  loading = false,
  walletBalance = 0,
}: {
  loading?: boolean;
  walletBalance?: number;
}) => {
  return (
    <Box>
      <LoadingTypography loading={loading} variant="h7" sx={{ mt: { xs: 2.5, tiny: 2.5, sm: 2.5, md: 4 } }}>
        Amount
      </LoadingTypography>
      <LoadingTypography loading={loading} sx={{ my: 1 }} variant="h4">
        ${(walletBalance / 100).toFixed(2)}
      </LoadingTypography>
    </Box>
  );
};

const SavedDigitalWalletPaymentMethod = ({
  loading = false,
  paymentMethod,
}: {
  loading?: boolean;
  paymentMethod?: PaymentMethod;
}) => {
  return (
    <Box>
      <LoadingTypography loading={loading} sx={{ mt: { xs: 2.5, tiny: 2.5, sm: 2.5, md: 4 } }} variant="h7">
        Payment Method
      </LoadingTypography>
      <LoadingTypography sx={{ mt: 2, mb: 1 }} variant="subtitle1">
        Card
      </LoadingTypography>
      <Grid item xs={12} sm={12}>
        <LoadingSkeleton loading={loading} width="100%">
          <SavedPaymentCard loading={loading} paymentMethod={paymentMethod} />
        </LoadingSkeleton>
      </Grid>
    </Box>
  );
};

export const SavedDigitalWallet: React.FC<SavedDigitalWalletProps> = ({
  loading = false,
  walletBalance = 0,
  paymentMethod,
  onContinue = () => undefined,
  onEdit = () => undefined,
}) => {
  return (
    <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
      <Box>
        <SavedDigitalWalletTitle loading={loading} />
        <SavedDigitalWalletBalance loading={loading} walletBalance={walletBalance} />
        <SavedDigitalWalletPaymentMethod loading={loading} paymentMethod={paymentMethod} />
        <Grid item xs={12} sm={12}>
          <LoadingButton
            sx={{ mt: { xs: 4, tiny: 4, sm: 4, md: 7.5 } }}
            variant="contained"
            fullWidth
            loading={loading}
            onClick={onContinue}
          >
            Continue to Vehicle Entry
          </LoadingButton>
        </Grid>
      </Box>
      <Box>
        {!onEdit && (
          <Grid item xs={12} sm={12}>
            <LoadingButton sx={{ mt: 2 }} variant="outlined" fullWidth loading={loading} onClick={onEdit}>
              Edit Digital Wallet
            </LoadingButton>
          </Grid>
        )}
        {appName !== "curbsuite.io" && (
          <Grid item xs={12} sm={12}>
            <NeedHelpButton sx={{ mt: 2 }} fullWidth />
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export interface SavedDigitalWalletProps {
  loading?: boolean;
  walletBalance?: number;
  paymentMethod?: PaymentMethod;
  onContinue?: () => void;
  onEdit?: () => void;
}

export default SavedDigitalWallet;
