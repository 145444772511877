import React, { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import Dialog from "@mui/material/Dialog";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import SignUpPayment from "@app.automotus.io/components/routes/signup/payment";
import LocalLevelError from "@app.automotus.io/components/common/LocalLevelError";
import { formatCurrency } from "common/format";
import SelectAmount, { LastTouchedStatus } from "../CurbPassSetup/SelectAmount";
import PaymentModal from "../CurbPassSetup/PaymentModal";
import SeeParkingRates from "../CurbPassSetup/SeeParkingRates";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_WALLET_WITH_PAYEE,
  CREATE_WALLET,
  CreateWalletData,
  CreateWalletVars,
  GET_USER_PROFILE,
  GET_INVOICE_BY_ID,
  GetInvoiceByIdData,
  GetInvoiceByIdVars,
} from "common/graphql";
import { useActivePayee } from "../../../hooks/useActivePayee";
import { OnboardingStatus } from "@app.automotus.io/components/common";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";

export const DEFAULT_AMOUNT = 1500;
const MINIMUM_AMOUNT = 500;

export const InvoiceCurbPassSetup: React.FC = () => {
  // TODO: I don't think anywhere in here is the invoice actually paid. Will need to update that.
  const paymentFormRef = useRef<{ scrollIntoPayButton: () => void }>(null);
  const state = useLocation().state as Record<string, unknown>;
  const { userProfile } = useUserProfile();
  const [selectedAmount, setAmount] = useState<number>(0);
  const [paymentModalOpen, setPaymentModalOpen] = useState<boolean>(false);
  const isAgreedToTermsOfService = ((state && state.isAgreedToTermsOfService) ?? false) as boolean;
  const [agreed, setAgreed] = useState<boolean>(isAgreedToTermsOfService);
  const [isFreeTrialDlgShown, setIsFreeTrialDlgShown] = useState<boolean>(false);
  const [isPaymentFormVisible, setIsPaymentFormVisible] = useState<boolean>(false);
  const [isValidAmount, setIsValidAmount] = useState<boolean>(true);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));
  const { activePayee } = useActivePayee();
  const [createWallet, { loading: createWalletLoading, reset: resetCreateWallet }] = useMutation<
    CreateWalletData,
    CreateWalletVars
  >(CREATE_WALLET, { refetchQueries: [GET_WALLET_WITH_PAYEE, GET_USER_PROFILE] });
  const location = useLocation();
  const { invoiceId } = (location.state || {}) as { invoiceId: string };

  const onToggleAgreed = () => {
    setAgreed((prev) => !prev);
  };

  const onClosePaymentModal = () => {
    setPaymentModalOpen(false);
  };

  const updateSettings = useCallback(async () => {
    const isFreeTrial = selectedAmount === 0;
    const desiredBalance = isFreeTrial ? 1500 : selectedAmount;
    const minimumBalance = desiredBalance * 0.25;

    try {
      await createWallet({
        variables: {
          payeeAccountId: activePayee?.payeeAccountId || "",
          desiredBalance,
          minimumBalance,
          isFreeTrial,
        },
      });
      resetCreateWallet();
    } catch (err) {
      console.error(err);
    }
  }, [createWallet, resetCreateWallet, activePayee, selectedAmount]);

  useEffect(() => {
    if (isPaymentFormVisible || paymentModalOpen) {
      (async () => {
        await updateSettings();
      })();
    }
  }, [selectedAmount, isPaymentFormVisible, updateSettings, paymentModalOpen]);

  const {
    data: invoiceData,
    refetch: refetchInvoice,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const refetchOnError = () => {
    if (invoiceError) {
      refetchInvoice();
    }
  };

  if (invoiceId && invoiceError) {
    return <LocalLevelError onTryAgain={refetchOnError} />;
  }

  const onAddPayment = async () => {
    hideFreeTrialDisclaimer();
    await updateSettings();
    if (isMobile) {
      setPaymentModalOpen(true);
      return;
    }
    setIsPaymentFormVisible(true);
    setTimeout(() => {
      paymentFormRef?.current?.scrollIntoPayButton();
    }, 0);
  };

  const onChangeAmount = (value: number, type: number) => {
    setAmount(value);
    setIsValidAmount(type === LastTouchedStatus.Option || value >= MINIMUM_AMOUNT);
  };

  const onboardingStatusValue = userProfile?.onboardingStatus ?? "profile";
  const isEditable = OnboardingStatus.isBefore(onboardingStatusValue, OnboardingStatus.PAYMENT);

  const showFreeTrialDisclaimer = () => {
    setIsFreeTrialDlgShown(true);
  };

  const hideFreeTrialDisclaimer = () => {
    setIsFreeTrialDlgShown(false);
  };

  const onAddPaymentMethodClick = () => {
    const isFreeTrial = selectedAmount === 0;
    if (isFreeTrial) {
      showFreeTrialDisclaimer();
    } else {
      onAddPayment();
    }
  };

  return (
    <Box sx={{ mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      <Typography sx={{ mb: 1.5 }} variant={isDesktop ? "h4" : isSmall ? "h6" : "h5"}>
        <span style={{ color: theme.palette.primary.main }}>CurbPass</span> Payment
      </Typography>
      <Box display={"flex"} flexDirection={"row"} sx={{ mb: 1 }} justifyContent="space-between">
        <Typography variant={"body1"}>Total Invoice Charge</Typography>
        {invoiceData && invoiceData.invoice && (
          <Typography variant={"body1"}>${formatCurrency(invoiceData.invoice.transaction_amount_due)}</Typography>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{ mb: 1, color: "rgba(0, 0, 0, 0.38)", textDecoration: "line-through" }}
        justifyContent="space-between"
      >
        <Typography variant={"body1"}>Administrative Fee</Typography>
        {invoiceData && invoiceData.invoice && (
          <Typography variant={"body1"}>${formatCurrency(invoiceData.invoice.admin_fee_due)}</Typography>
        )}
      </Box>
      <Box display={"flex"} flexDirection={"row"} sx={{ mb: 2 }} justifyContent="space-between">
        <Typography variant={"body1"}>CurbPass Balance</Typography>
        <Typography variant={"body1"}>
          {selectedAmount === 0 ? "Free Trial" : `$${formatCurrency(selectedAmount)}`}
        </Typography>
      </Box>
      <Dialog
        open={isFreeTrialDlgShown}
        onBackdropClick={hideFreeTrialDisclaimer}
        PaperProps={{
          sx: {
            borderRadius: 1.5,
            minWidth: { lg: 572 },
            px: 3,
            py: 2,
            display: "flex",
          },
        }}
      >
        <Box mb={3}>
          <Typography variant="h7" sx={{ fontWeight: 500 }}>
            Terms of Service
          </Typography>
        </Box>
        <Box mb={3}>
          <Typography variant="body1">
            By selecting 'try for free' your CurbPass won't be loaded until after your next park.
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton
            onClick={hideFreeTrialDisclaimer}
            sx={{ mr: "19px", textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            variant="text"
          >
            Select Amount
          </LoadingButton>
          <LoadingButton
            onClick={onAddPayment}
            sx={{ textTransform: "none", fontWeight: 600, fontSize: "18px" }}
            variant="text"
          >
            Agree
          </LoadingButton>
        </Box>
      </Dialog>
      <fieldset disabled={!isEditable} style={{ border: 0, padding: 0, margin: 0 }}>
        <Box borderRadius={2} sx={{ p: 1.5, backgroundColor: `rgba(13, 85, 191, 0.08)` }}>
          <Typography variant={"h7"} sx={{ fontWeight: 500 }}>
            Fund your CurbPass
          </Typography>
          <Typography variant={"body1"} sx={{ mb: 2 }}>
            Add funds now to save on fees later.
          </Typography>
          <SelectAmount isBillByMail disabled={!isEditable} amount={selectedAmount} onChange={onChangeAmount} />
        </Box>
        <SeeParkingRates isBillByMail />
        <Divider sx={{ mt: 2.5, mb: 1 }} />
        <Box display={"flex"} flexDirection={"row"} sx={{ mb: 3 }} justifyContent="space-between">
          <Typography variant={"h7"}>Total Due</Typography>
          {invoiceData && invoiceData.invoice && (
            <Typography variant={"body1"}>
              $
              {formatCurrency(
                selectedAmount +
                  invoiceData.invoice.transaction_amount_due +
                  invoiceData.invoice.admin_fee_due -
                  invoiceData.invoice.admin_fee_amount_waived,
              )}
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: 3 }}>
          <Box>
            <Grid container direction="row" alignItems="left" rowSpacing={1} sx={{ pl: 1 }}>
              <Grid item xs={12}>
                <TermsOfServiceCheckbox isChecked={agreed} onToggleCheck={onToggleAgreed} />
              </Grid>
            </Grid>
          </Box>
          {!isMobile && isPaymentFormVisible && <Divider sx={{ mt: 3 }} />}
          {(isMobile || !isPaymentFormVisible) && (
            <Grid container direction="row" sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <LoadingButton
                  id="add-payment-method"
                  disabled={!agreed || !isValidAmount}
                  onClick={onAddPaymentMethodClick}
                  variant="contained"
                  fullWidth
                  loading={createWalletLoading}
                  sx={{
                    textTransform: "unset",
                    py: isMobile ? 0.75 : 1,
                    fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                    height: { xs: "30px", tiny: "36px", lg: "42px" },
                    fontWeight: 400,
                  }}
                >
                  Add Payment Method
                </LoadingButton>
              </Grid>
            </Grid>
          )}
          {!isMobile && isPaymentFormVisible && (
            <SignUpPayment
              ref={paymentFormRef}
              isValid={agreed && isValidAmount}
              isTryingForFree={!selectedAmount}
              invoiceId={invoiceId}
            />
          )}
          {isMobile && <PaymentModal amount={selectedAmount} open={paymentModalOpen} onClose={onClosePaymentModal} />}
        </Box>
      </fieldset>
    </Box>
  );
};

const TermsOfServiceCheckbox: React.FC<{
  onToggleCheck: () => void;
  isChecked: boolean;
}> = ({ onToggleCheck, isChecked }) => {
  const theme = useTheme();
  const location = useLocation();
  const { invoiceId } = (location.state || {}) as { invoiceId?: string };

  const navigate = useNavigate();

  return (
    <FormGroup>
      <FormControlLabel
        control={<Checkbox sx={{ fontSize: "1.125rem" }} checked={isChecked} onChange={onToggleCheck} />}
        label={
          <Typography color={theme.palette.text.primary}>
            I agree to the{" "}
            <Link
              component={RouterLink}
              to="/signup/terms-of-service"
              state={{ sourceRoute: "/signup/payment", invoiceId }}
              onClick={() => {
                navigate("/signup/terms-of-service");
              }}
            >
              Terms of Service
            </Link>
          </Typography>
        }
      />
    </FormGroup>
  );
};

export default InvoiceCurbPassSetup;
