import React, { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useRef } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import CardNumberField from "@app.automotus.io/components/forms/PaymentMethodFields/CardNumberField";
import ExpiryField from "@app.automotus.io/components/forms/PaymentMethodFields/ExpiryField";
import CVCField from "@app.automotus.io/components/forms/PaymentMethodFields/CVCField";
import ZipCodeField from "@app.automotus.io/components/forms/PaymentMethodFields/ZipCodeField";
import Grid from "@mui/material/Grid";
import { usePaymentsHelpers } from "../../../hooks/usePaymentsHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import { cardMethodFormValidationSchema } from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm";
import { CardMethodFormFields } from "@app.automotus.io/components/forms/PaymentMethodForm/CardMethodForm/CardMethodFormFields";
import { walletSetupStateVar } from "@app.automotus.io/components/scenes/SignUp/reactiveVars";
import { SavedDigitalWallet } from "@app.automotus.io/components/scenes/SignUp/DigitalWalletSetup/SavedDigitalWallet";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import {
  CREATE_PAYMENT_METHOD,
  CreatePaymentMethodData,
  CreatePaymentMethodVars,
  GET_USER_PROFILE,
  LOAD_WALLET,
  LoadWalletData,
  LoadWalletVars,
} from "common/graphql";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import { PartnerLandingPageParkingRatesAndHoursModal } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal";
import { FormObserver } from "@app.automotus.io/components/routes/signup/helpers/FormObserver";
import { paymentInfoVar } from "@app.automotus.io/components/routes/signup/helpers/reactiveVar";
import { SNACKBAR_MSGS, useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import GooglePayButton from "@app.automotus.io/components/forms/PaymentMethodFields/GooglePayButton";
import ApplePayButton from "@app.automotus.io/components/forms/PaymentMethodFields/ApplePayButton";
import { useActivePayee } from "../../../hooks/useActivePayee";
import { useActiveWallet } from "../../../hooks/useActiveWallet";
import { useApplePay } from "../../../hooks/useApplePay";
import { formatCurrency } from "common/format";
import { useUserProfile } from "@app.automotus.io/components/hooks";

const emptyDigitalWalletInfoFormValues = {
  cardNumber: "",
  expiry: "",
  cvc: "",
  zipCode: "",
  walletBalance: 0,
};

/**
 * Component that allows a user to set up his digital wallet for the first time.
 * Creates a checkout session with stripe then redirects the user to the session
 * upon successful creation.
 */
type DigitalWalletSetupHandler = {
  scrollIntoPayButton: () => void;
};

const MOBILE_PAYMENTS_ENABLED = false;

export const DigitalWalletSetup: ForwardRefRenderFunction<DigitalWalletSetupHandler, DigitalWalletSetupProps> = (
  { canceled = false, defaultWalletValue = null, isValid = true, isTryingForFree, invoiceId },
  ref,
) => {
  const payButtonRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const theme = useTheme();
  // TODO: handle error
  const [{ addPaymentMethodCard, payInvoice }, { clientLoading }] = usePaymentsHelpers();
  const { activePayee } = useActivePayee();
  const { wallet } = useActiveWallet();
  const [loadWallet] = useMutation<LoadWalletData, LoadWalletVars>(LOAD_WALLET);
  const { publishSnackbarMessage } = useSnackPackContext();
  const { performApplePayPayment } = useApplePay();
  const [createPaymentMethod] = useMutation<CreatePaymentMethodData, CreatePaymentMethodVars>(CREATE_PAYMENT_METHOD);
  const [finishOnboarding] = useMutation<FinishOnboardingData>(FINISH_ONBOARDING);
  const { userProfile } = useUserProfile();
  const location = useLocation();

  useImperativeHandle(
    ref,
    () => {
      return {
        scrollIntoPayButton() {
          if (payButtonRef?.current) payButtonRef.current.scrollIntoView();
        },
      };
    },
    [],
  );

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const handleSubmit = async (
    values: ValidatedDigitalWalletInfoFormValues,
    { setSubmitting }: FormikHelpers<ValidatedDigitalWalletInfoFormValues>,
  ) => {
    if (!activePayee || !userProfile) {
      return;
    }

    let paymentMethodId: string;
    try {
      const paymentMethod = await addPaymentMethodCard({
        ...values,
        makeDefault: true,
      });

      paymentMethodId = paymentMethod.paymentMethodId;
    } catch (err) {
      console.error("failed to add payment method", err);
      publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_METHOD_SETUP_FAILED);
      return;
    }

    // If we want a non-zero wallet balance, fill the wallet
    if (!userProfile.account.isFreeTrial) {
      try {
        await loadWallet({
          variables: { payeeAccountId: activePayee.payeeAccountId },
          refetchQueries: [GET_USER_PROFILE],
        });
      } catch (err) {
        console.error("failed to load wallet", err);
        publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_METHOD_SETUP_FAILED);
        return;
      }
    }

    if (invoiceId) {
      try {
        await payInvoice({ invoiceId, paymentMethodId });
      } catch (err) {
        console.error("failed to pay invoice", err);
        publishSnackbarMessage(SNACKBAR_MSGS.PAYMENT_FAILED);
        return;
      }
    }

    finishOnboarding().catch((err) => {
      console.error(err);
    });

    publishSnackbarMessage(SNACKBAR_MSGS.DIGITAL_WALLET_CREATED_SUCCESS);
    setSubmitting(false);

    navigate("/signup/finish", { state: location.state });
  };

  const walletSetupState = walletSetupStateVar();

  const paymentInfo = useReactiveVar(paymentInfoVar);

  if (walletSetupState) {
    return <SavedDigitalWallet {...walletSetupState} onContinue={() => navigate("/signup/finish")} />;
  }

  const onGooglePay = () => {
    console.log("Google Pay Clicked");
  };

  const onApplePay = () => {
    performApplePayPayment(
      {
        countryCode: "US",
        currencyCode: "USD",
        label: "CurbPass Setup",
        amount: formatCurrency(wallet?.desiredWalletBalance || 0),
      },
      async (session, event) => {
        console.log(JSON.stringify(event.payment));
        try {
          await createPaymentMethod({
            variables: {
              payeeAccountId: activePayee?.payeeAccountId || "",
              isDefault: true,
              gatewayAccess: {
                opaqueData: {
                  dataDescriptor: "COMMON.APPLE.INAPP.PAYMENT",
                  dataValue: Buffer.from(JSON.stringify(event.payment.token.paymentData)).toString("base64"),
                },
              },
              // TODO: make sure billing contact is present... what if it's not?
              billingZip: event.payment.billingContact?.postalCode || "",
            },
          });
        } catch (err) {
          console.error("failed to create payment method", err);
          session.abort();
        }
      },
      (session, event) => {
        console.log("payment canceled", event);
      },
    );
  };

  return (
    <Box sx={{ mt: { xs: 2, lg: 3 } }}>
      <Typography sx={{ typography: { xs: "h6", tiny: "h5", md: "h4" } }}>Payment Method</Typography>
      <Typography sx={{ mb: 3 }} variant="body1">
        Your payment method will be saved.
      </Typography>
      {MOBILE_PAYMENTS_ENABLED && (
        <>
          <Grid container justifyContent="space-between" columnSpacing={2} ref={payButtonRef}>
            <Grid item xs={6}>
              <GooglePayButton disabled={!isValid} onClick={onGooglePay} />
            </Grid>
            <Grid item xs={6}>
              <ApplePayButton disabled={!isValid} onClick={onApplePay} />
            </Grid>
          </Grid>
          <Box sx={{ my: 3 }}>
            <Divider>
              <Typography variant={"body1"} sx={{ color: theme.palette.text.secondary }}>
                or
              </Typography>
            </Divider>
          </Box>
        </>
      )}
      <Formik
        initialValues={{
          cardNumber: paymentInfo.cardNumber || emptyDigitalWalletInfoFormValues.cardNumber,
          expiry: paymentInfo.expiry || emptyDigitalWalletInfoFormValues.expiry,
          cvc: paymentInfo.cvc || emptyDigitalWalletInfoFormValues.cvc,
          zipCode: paymentInfo.zipCode || emptyDigitalWalletInfoFormValues.zipCode,
          walletBalance: paymentInfo.walletBalance || defaultWalletValue,
        }}
        onSubmit={handleSubmit}
        validationSchema={cardMethodFormValidationSchema.shape({
          cardNumber: CardNumberField.validationSchema,
          expiry: ExpiryField.validationSchema,
          cvc: CVCField.validationSchema,
          zipCode: ZipCodeField.validationSchema,
        })}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} onReset={props.handleReset} noValidate>
            <FormObserver formType="payment" />
            <Box sx={{ mt: 2 }}>
              <CardMethodFormFields loading={clientLoading} disabled={!isValid} />
            </Box>
            {canceled && (
              <Alert sx={{ mt: 3 }} severity="error">
                You need to set up a payment method before proceeding
              </Alert>
            )}
            <Grid item xs={12} sm={12}>
              <LoadingButton
                sx={{ mt: 3, textTransform: "unset", fontSize: { xs: "14px", tiny: "16px", lg: "18px" } }}
                variant="contained"
                fullWidth
                id="form-submit"
                type="submit"
                disabled={!isValid}
                loading={clientLoading || props.isSubmitting}
              >
                {isTryingForFree ? "Save Payment Method" : "Pay"}
              </LoadingButton>
            </Grid>
          </Form>
        )}
      </Formik>
      <PartnerLandingPageParkingRatesAndHoursModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        payeeAccountId={activePayee?.payeeAccountId || ""}
      />
    </Box>
  );
};

type RequiredCardInfoFormValues = "cardNumber" | "expiry" | "cvc";

export interface DigitalWalletInfoFormValues {
  walletBalance: number | null;
  cardNumber: string;
  expiry: string;
  cvc: string;
  zipCode: string;
}

export type ValidatedDigitalWalletInfoFormValues = {
  [P in keyof DigitalWalletInfoFormValues]: P extends RequiredCardInfoFormValues
    ? NonNullable<DigitalWalletInfoFormValues[P]>
    : DigitalWalletInfoFormValues[P];
};

export interface DigitalWalletSetupProps {
  /** Default entered wallet value expressed as an integer number of the currency's smallest denomination */
  defaultWalletValue?: number | null;
  /** Indicates whether or not wallet setup was canceled */
  canceled?: boolean;
  /** Maximum (inclusive) wallet value expressed as an integer number of the smallest denomination of the user's currency. Defaults to $100 */
  maxWalletValue?: number;
  /** Minimum (inclusive) wallet value expressed as an integer number of cents. Defaults to $10*/
  minWalletValue?: number;
  /** True when TOS is agreed and the amount is valid*/
  isValid?: boolean;
  /** Indicates wheter the user is trying for free */
  isTryingForFree: boolean;
  /** Optional invoice ID to pay */
  invoiceId?: string;
}

const FINISH_ONBOARDING = gql`
  mutation FinishOnboarding {
    finishOnboarding: finish_onboarding {
      finishedAt: finished_at
    }
  }
`;

interface FinishOnboardingData {
  finishOnboarding: {
    finishedAt: string;
  };
}

export default forwardRef(DigitalWalletSetup);
