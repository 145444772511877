import React from "react";
import automotusLogoBlueSrc from "./automotusLogoBlue.svg";
import curbPassLogoBlueSrc from "./cubPassLogoBlue.svg";
import AppLogoBase, { AppLogoProps } from "@app.automotus.io/components/images/AppLogo/AppLogoBase";

export const LogoBlue: React.FC<AppLogoProps> = (props) => (
  <AppLogoBase
    automotusLogoSrc={automotusLogoBlueSrc}
    curbPassLogoSrc={curbPassLogoBlueSrc}
    curbSuiteLogoSrc={automotusLogoBlueSrc}
    {...props}
  />
);

export default LogoBlue;
