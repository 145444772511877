import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { useSearchParams, useNavigate } from "react-router-dom";
import formatDate from "date-fns/format";
import { LoadingSkeleton } from "@app.automotus.io/components/LoadingSkeleton";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import { useQuery } from "@apollo/client";
import { GET_INVOICE_BY_ID, GetInvoiceByIdData, GetInvoiceByIdVars } from "common/graphql";
import LocalLevelError from "../common/LocalLevelError";
import { formatCurrency } from "common/format";
import InvoiceTransactionsTableView from "../InvoiceTransactions/InvoiceTransactionsTableView";
import InvoiceTransactionsCardList from "../InvoiceTransactions/InvoiceTransactionsCardList";
import sumBy from "lodash/sumBy";

/**
 * The invoice page is a critical page for standalone bill-by-mail payments.
 * It should display invoice details, total amount owed, a brief bill as well as a cost breakdown of each park event.
 * The page should be well-established and user-friendly, and therefore it must follow design specifications strictly.
 */
export const BillByMailPaymentInvoice: React.FC<BillByMailPaymentInvoiceProps> = ({
  invoiceId,
  onMakeAPayment = () => undefined,
  onDisputeTransaction = () => undefined,
}) => {
  const {
    data: invoiceData,
    refetch: refetchInvoice,
    loading: invoiceLoading,
    error: invoiceError,
  } = useQuery<GetInvoiceByIdData, GetInvoiceByIdVars>(GET_INVOICE_BY_ID, {
    variables: { invoiceId },
  });

  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isBillSummaryOpen = searchParams.get("viewTransactions") === "true";

  if ((invoiceId && invoiceError) || (!invoiceData?.invoice && !invoiceLoading)) {
    return <LocalLevelError onTryAgain={refetchInvoice} />;
  }

  const invoice = invoiceData?.invoice;
  const invoiceTransactions =
    invoice?.transactions.map((transaction) => {
      const t = transaction.transaction_log_no_balance;
      return {
        id: t.transactionId,
        date: new Date(t.endTime),
        startTime: new Date(t.startTime),
        endTime: new Date(t.endTime),
        address: t.addressStreet,
        city: `${t.addressCity}, ${t.addressState}`,
        vehicleId: invoice.registered_vehicle.id,
        vehicle: `${t.licensePlateStateCode} ${t.licensePlateNumber}`,
        amount: sumBy(transaction.park.policyRateDetails, "price"),
        index: t.index,
        status: t.status,
      };
    }) || [];

  return (
    <>
      {!isBillSummaryOpen && (
        <Box sx={{ width: { xs: "100%" } }}>
          <LoadingSkeleton loading={invoiceLoading}>
            <Typography
              variant="h4"
              sx={{ mt: { xs: 3, md: 2 } }}
              fontSize={{ xs: "1.5rem", md: "2.125rem" }}
              fontWeight={500}
            >
              Invoice Details
            </Typography>
            <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
              Invoice issued on {formatDate(new Date(invoice?.created_at || 0), "MMMM dd, yyyy")}
            </Typography>
          </LoadingSkeleton>
          <LoadingSkeleton loading={invoiceLoading} width="100%">
            <Stack spacing={1} sx={{ mt: { xs: 1.5, md: 2 } }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1">Invoice Number</Typography>
                <Typography variant="body1">{String(invoice?.index).padStart(6, "0")}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="body1">License Plate Number</Typography>
                <Typography variant="body1">{invoice?.registered_vehicle.license_plate}</Typography>
              </Box>
            </Stack>
          </LoadingSkeleton>
          {!isMobile && (
            <Box sx={{ mt: { xs: 2, md: 2 } }}>
              <LoadingSkeleton loading={invoiceLoading} width="100%">
                <Button
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    navigate(`/paybill/${invoiceId}?viewTransactions=true`);
                  }}
                >
                  View Transactions
                </Button>
              </LoadingSkeleton>
            </Box>
          )}
          <Box sx={{ display: "flex", mt: { xs: 3, md: 4 }, justifyContent: "space-between" }}>
            <LoadingSkeleton loading={invoiceLoading}>
              <Box>
                <Typography variant="h4" fontSize={{ xs: "1.5rem", md: "2.125rem" }} fontWeight={500}>
                  Pay Invoice
                </Typography>
                <Typography variant="caption" color={(theme) => theme.palette.text.secondary}>
                  Your payment is due by {formatDate(new Date(invoice?.due_at || 0), "MMMM do, yyyy")}
                </Typography>
              </Box>
            </LoadingSkeleton>
          </Box>
          <LoadingSkeleton loading={invoiceLoading} width="100%">
            <Box>
              <Stack spacing={1} sx={{ mt: { xs: 1.5, md: 2 } }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1">Total Parking Charges</Typography>
                  <Typography variant="body1">${formatCurrency(invoice?.transaction_amount_due || 0)}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="body1">Administration Fee</Typography>
                  <Typography variant="body1">${formatCurrency(invoice?.admin_fee_due || 0)}</Typography>
                </Box>
              </Stack>
              <Divider sx={{ my: 1 }} />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography variant="h7">Total Due</Typography>
                <Typography variant="h7">
                  ${formatCurrency((invoice?.transaction_amount_due || 0) + (invoice?.admin_fee_due || 0))}
                </Typography>
              </Box>
            </Box>
          </LoadingSkeleton>
          <Box sx={{ mt: { xs: 3, md: 5 } }}>
            <LoadingSkeleton loading={invoiceLoading} width="100%">
              <Button sx={{ textTransform: "none" }} variant="contained" fullWidth onClick={onMakeAPayment}>
                Make a Payment
              </Button>
            </LoadingSkeleton>
          </Box>
          {isMobile && (
            <Box sx={{ mt: 2 }}>
              <LoadingSkeleton loading={invoiceLoading} width="100%">
                <Button sx={{ textTransform: "none" }} variant="text" fullWidth onClick={() => navigate(-1)}>
                  Back
                </Button>
              </LoadingSkeleton>
            </Box>
          )}
          <Box sx={{ my: { xs: 2, tiny: 3 }, py: 1.5, display: "flex", justifyContent: "center" }}>
            <LoadingSkeleton loading={invoiceLoading}>
              <Typography component="span" variant="body1">
                Incorrectly billed?{" "}
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={onDisputeTransaction}
                >
                  Dispute transaction
                </Typography>
              </Typography>
            </LoadingSkeleton>
          </Box>
          {isMobile && (
            <Box sx={{ pt: 2, mt: 2, backgroundColor: (theme) => theme.palette.grey[100] }}>
              <LoadingSkeleton loading={invoiceLoading}>
                <Typography variant="h5" fontSize={"24px"} fontWeight={500} sx={{ mb: 2 }}>
                  Transactions
                </Typography>
              </LoadingSkeleton>
              <InvoiceTransactionsCardList loading={invoiceLoading} data={invoiceTransactions} />
            </Box>
          )}
        </Box>
      )}
      {isBillSummaryOpen && (
        <Box my={2} sx={{ width: { xs: "100%" } }}>
          <LoadingSkeleton loading={invoiceLoading}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h5" fontWeight={500}>
                Transactions
              </Typography>
            </Box>
          </LoadingSkeleton>
          <InvoiceTransactionsTableView loading={invoiceLoading} rows={invoiceTransactions} />
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <LoadingSkeleton loading={invoiceLoading}>
              <Typography py={1.5} component="span" variant="body1">
                Incorrectly billed?{" "}
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                    color: (theme) => theme.palette.primary.main,
                    cursor: "pointer",
                  }}
                  onClick={onDisputeTransaction}
                >
                  Dispute transaction
                </Typography>
              </Typography>
            </LoadingSkeleton>
          </Box>
        </Box>
      )}
    </>
  );
};

export interface BillSummaryData {
  id: number;
  date: Date;
  startTime: Date;
  endTime: Date;
  address: string;
  city: string;
  vehicle: string;
  amount: number;
}

export interface BillByMailPaymentInvoiceProps {
  /** ID of the invoice */
  invoiceId: string;
  onMakeAPayment?: () => void;
  /** Dispute transaction button handler */
  onDisputeTransaction?: () => void;
}

export default BillByMailPaymentInvoice;
