import React, { useEffect, useRef } from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CodeInput from "react-verification-code-input";
import { useIsMobile, useWindowDimensions } from "@app.automotus.io/components/hooks";
import Link from "@mui/material/Link";
import { parsePhoneNumber } from "libphonenumber-js";
import { styled, useTheme } from "@mui/material/styles";

/**
 * Component that allows a user to enter and confirm her confirmation code for
 * passwordless login.
 *
 * Forwards a ref to the submission button element.
 */
export const PasswordlessConfirmationCodeEntry = React.forwardRef<
  HTMLButtonElement,
  PasswordlessConfirmationCodeEntryProps
>(
  (
    {
      phoneNumber = "",
      confirmationCode = "",
      onChangeConfirmationCode = () => undefined,
      onClickGetANewCode = () => undefined,
      loading = false,
      isConfirmationCodeIncorrect = false,
    },
    ref,
  ) => {
    const theme = useTheme();
    const themeSpacing = Number(theme.spacing(1).replace("px", ""));
    const { width: windowWidth } = useWindowDimensions();
    const isMobile = useIsMobile();
    const contentWidth = windowWidth - 4 * themeSpacing;

    let fieldWidth = DEFAULT_FIELD_WIDTH;
    if (isMobile && contentWidth < fieldWidth * CONFIRMATION_CODE_LENGTH) {
      fieldWidth = Math.floor(contentWidth / CONFIRMATION_CODE_LENGTH);
    }

    const textAlign = "center";
    const formattedPhoneNumber = parsePhoneNumber(phoneNumber).formatInternational();

    const codeInputRef = useRef<CodeInput>(null);
    useEffect(() => {
      /* If confirmation code is unset, clear the input and restore focus.
       * We can't do this while the component is disabled, so wait for loading
       * to complete.
       */
      if (!loading && confirmationCode.length === 0) {
        codeInputRef.current && codeInputRef.current.__clearvalues__();
      }
    }, [confirmationCode, loading]);

    return (
      <Box>
        <Typography variant="h4" align={textAlign}>
          Verify your number
        </Typography>
        <Typography variant="body1" align={textAlign} sx={{ mb: 3 }}>
          {`Enter the ${CONFIRMATION_CODE_LENGTH}-digit code sent to `}
          <Typography variant="body3">{formattedPhoneNumber}</Typography>
        </Typography>
        <Box display="flex" justifyContent="center">
          <Box display="inline-block" ref={ref}>
            <StyledCodeInput
              autoFocus
              ref={codeInputRef}
              type="number"
              values={confirmationCode.split("")}
              fields={CONFIRMATION_CODE_LENGTH}
              onChange={onChangeConfirmationCode}
              fieldWidth={fieldWidth}
            />
            <Typography
              variant="body3"
              mt={3}
              sx={{ display: "flex", flexDirection: "column", alignItems: "center", alignSelf: "stretch" }}
            >
              Haven't received it?
              <Link disabled={loading} variant="body4" component="button" onClick={onClickGetANewCode}>
                Request another code
              </Link>
            </Typography>
          </Box>
        </Box>
        {isConfirmationCodeIncorrect && (
          <Alert severity="error" sx={{ mt: 5 }}>
            Your confirmation code was incorrect
          </Alert>
        )}
      </Box>
    );
  },
);

/** Override font family of CodeInput */
const StyledCodeInput = styled(CodeInput)(({ theme }) => ({
  padding: "0 66px",
  display: "flex",
  flexDirection: "column",
  alignSelf: "stretch",
  alignItems: "flex-start",
  "& input": {
    fontFamily: `${theme.typography.fontFamily} !important`,
  },
  "& :nth-child(1)": {
    borderTopLeftRadius: "4px !important",
    borderBottomLeftRadius: "4px !important",
  },
  "& :nth-child(6)": {
    borderTopRightRadius: "4px !important",
    borderBottomRightRadius: "4px !important",
  },
}));

const DEFAULT_FIELD_WIDTH = 54;
const CONFIRMATION_CODE_LENGTH = 6;

/** Props passed to initialize a {@link PasswordlessConfirmationCodeEntry} component */
export interface PasswordlessConfirmationCodeEntryProps {
  /** Phone number of the user. Can take any format */
  phoneNumber: string;
  /** Confirmation code entered by the user thus far */
  confirmationCode?: string;
  /** Change handler fired when confirmation code entered is changed */
  onChangeConfirmationCode?: (value: string) => void;
  /** Submit handler fired when user clicks the "Confirm code" button */
  onSubmitConfirmationCode?: (confirmationCode: string) => void;
  /** Click handler fired when a user clicks the "Get a new code" button */
  onClickGetANewCode?: React.MouseEventHandler;
  /** Indicates whether the component is in a loading state */
  loading?: boolean;
  /** Indicates whether the confirmation code was incorrect after last submission */
  isConfirmationCodeIncorrect?: boolean;
}

export default PasswordlessConfirmationCodeEntry;
