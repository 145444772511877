import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { Form, Formik } from "formik";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import {
  ProfileInformationFields,
  ProfileInformationValues,
} from "@app.automotus.io/components/forms/ProfileInformationFields";
import { useMutation } from "@apollo/client";
import {
  UPDATE_PROFILE_ONBOARDING,
  UPDATE_PROFILE_ONBOARING_REFETCH_QUERIES,
  UpdateProfileOnboardingData,
  UpdateProfileOnboardingVars,
} from "common/graphql/mutations";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useLocation, useNavigate } from "react-router-dom";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";
import { FormObserver } from "@app.automotus.io/components/routes/signup/helpers/FormObserver";
import DropdownModal from "@app.automotus.io/components/modals/DropdownModal";
import { CITY_OPTIONS } from "common/constants";
import { useActivePayee } from "../../../hooks/useActivePayee";
import { profileInfoVar } from "@app.automotus.io/components/routes/signup/helpers/reactiveVar";
import { useAuth0 } from "@auth0/auth0-react";

export const ProfileSetup: ProfileSetupComponent = ({ initialValues }) => {
  const navigate = useNavigate();
  const SPACING = 2;
  const isMobile = useIsMobile();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmall = useMediaQuery(theme.breakpoints.down("tiny"));

  const { logout } = useAuth0();
  const { userProfile } = useUserProfile();
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { activePayee, error: activePayeeError, setActivePayee } = useActivePayee();
  const [defaultLocation, setDefaultLocation] = useState<string>(activePayee?.cityName || "");
  const onZoneSelect = ({ value }: { value: string }): void => {
    // TODO: need to use city of invoice
    const city = CITY_OPTIONS.find((item) => item.value === value);
    if (!city) {
      console.error(`failed to find city ${value}`);
      return;
    }
    setActivePayee({
      cityName: city.value,
      payeeAccountId: city.payeeAccountId,
    });
    setDialogOpen(false);
  };

  useEffect(() => {
    const paths = location.pathname.split("/");

    if (paths.includes("signup") && paths.includes("profile")) {
      if (defaultLocation === "") {
        if (activePayee) {
          setDefaultLocation(activePayee.cityName);
        } else {
          setDialogOpen(true);
        }
      }
    }
  }, [location, defaultLocation, activePayee, activePayeeError]);

  useEffect(() => {
    if (activePayeeError) {
      console.error("failed to determine active payee", activePayeeError);
    }
  }, [activePayeeError]);

  const [updateBasicProfile, { error, called, reset, loading: updateBasicProfileLoading }] = useMutation<
    UpdateProfileOnboardingData,
    UpdateProfileOnboardingVars
  >(UPDATE_PROFILE_ONBOARDING, {
    refetchQueries: UPDATE_PROFILE_ONBOARING_REFETCH_QUERIES,
    onCompleted: () => {
      navigate("../vehicle", { state: location.state });
    },
  });

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Box sx={{ mt: isMobile ? 1.5 : 3, px: isMobile ? 0 : 2 }}>
      <Typography variant={isDesktop ? "h4" : isSmall ? "h6" : "h5"}>
        <span style={{ color: theme.palette.primary.main }}>CurbPass</span> Profile
      </Typography>
      <Typography variant="body2" sx={{ mb: SPACING }}>
        We just need a few quick details.
      </Typography>

      <DropdownModal
        defaultValue={defaultLocation}
        options={CITY_OPTIONS}
        onClose={handleDialogClose}
        onSubmit={onZoneSelect}
        open={dialogOpen}
        paperProps={{
          sx: {
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
            width: "290px",
            borderRadius: 1,
          },
        }}
      />
      <Box sx={{ mt: 3 }}>
        <Formik<ProfileSetupFormValues>
          initialValues={{
            ...initialValues,
          }}
          validationSchema={ProfileSetup.validationSchema}
          enableReinitialize={true}
          onSubmit={async (values: ProfileSetupFormValues, actions) => {
            try {
              const {
                firstName: givenName,
                lastName: familyName,
                email,
                companies: companySelection,
                isAgreedToEmailUpdates: emailUpdates,
                phoneNumber,
              } = values;
              const selectedCompanies = companySelection ? `{${companySelection.join(", ")}}` : "{}";
              const variables: UpdateProfileOnboardingVars = {
                accountId: userProfile?.account.id || "",
                userId: userProfile?.id || "",
                fullName: `${givenName} ${familyName}`,
                givenName,
                familyName,
                email,
                phoneNumber,
                // Value is required, so will not be null
                selectedCompanies,
                emailUpdates,
                tosAcceptedVersion: "2022-03-01",
                tosAcceptedAt: new Date(),
              };

              profileInfoVar({ ...values });
              await updateBasicProfile({ variables });
            } catch (err) {
              // TODO: error analytics
              console.error(err);
            } finally {
              actions.setSubmitting(false);
              reset();
            }
          }}
        >
          {(props) => (
            <Form>
              <fieldset disabled={updateBasicProfileLoading} style={{ border: 0, padding: 0, margin: 0 }}>
                <FormObserver formType="profile" />
                <ProfileInformationFields disabled={updateBasicProfileLoading} />
                <Grid container direction="row" rowSpacing={2} sx={{ mt: 3 }}>
                  <Grid item xs={12}>
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      fullWidth
                      loading={props.isSubmitting}
                      sx={{ textTransform: "none", fontSize: { xs: "14px", tiny: "16px", lg: "18px" } }}
                    >
                      Save and Continue
                    </LoadingButton>
                  </Grid>
                  <Grid item xs={12}>
                    {called && error && (
                      <Alert severity="error" sx={{ mt: 2 }}>
                        An error occurred when saving your profile information. Please try again.
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </fieldset>
            </Form>
          )}
        </Formik>
      </Box>
      <Button
        variant={"text"}
        fullWidth
        sx={{ textTransform: "none", p: 0, fontSize: { xs: "14px", tiny: "16px", lg: "18px" } }}
        onClick={() => logout({ returnTo: `${window.location.origin}/login` })}
      >
        Back
      </Button>
    </Box>
  );
};

ProfileSetup.defaultInitialValues = {
  ...ProfileInformationFields.defaultInitialValues,
  isAgreedToTermsOfService: false,
  isAgreedToEmailUpdates: true,
};

const validationSchema = ProfileInformationFields.validationSchema;

ProfileSetup.validationSchema = validationSchema;

export interface ProfileSetupProps {
  initialValues: ProfileSetupFormValues;
}

export type ProfileSetupFormValues = ProfileInformationValues & {
  isAgreedToEmailUpdates: boolean;
  isAgreedToTermsOfService: boolean;
};

export interface ProfileSetupComponent extends React.FC<ProfileSetupProps> {
  defaultInitialValues: ProfileSetupProps["initialValues"];
  validationSchema: typeof validationSchema;
}

export default ProfileSetup;
