import React, { useCallback, useEffect, useState } from "react";
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@mui/material/TextField";
import AutoNumeric, { Options as AutoNumericOptions } from "autonumeric";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import currencySymbols from "currency-symbol-map";

/**
 * Currency input field. Allows a user to enter a currency as a human-readable
 * string including cents.
 */
export const CurrencyField: React.FC<CurrencyFieldProps> = ({
  value = null,
  currencyCode = "usd",
  allowNegativeValue = false,
  label,
  HelperModal = null,
  disabled = false,
  ...restProps
}) => {
  const theme = useTheme();
  const currencySymbol = currencyCode && currencySymbols(currencyCode.toUpperCase());
  const [autonumeric, setAutonumeric] = useState<AutoNumeric | null>(null);
  const [initialValue] = useState(value);

  const getInputValue = useCallback(() => {
    if (autonumeric) {
      const asNumber = autonumeric.getNumber();

      if (asNumber !== null) {
        return asNumber * 100;
      }
    }

    return null;
  }, [autonumeric]);

  useEffect(() => {
    return () => {
      autonumeric?.remove();
    };
  }, [autonumeric]);

  useEffect(() => {
    const curInputValue = getInputValue();

    if (curInputValue !== null && value !== curInputValue) {
      autonumeric?.set(value && value / 100);
    }
  }, [autonumeric, value, getInputValue]);

  const ref = useCallback(
    (node: HTMLInputElement | null) => {
      if (node !== null) {
        setAutonumeric(
          new AutoNumeric(node, initialValue && initialValue / 100, {
            minimumValue: allowNegativeValue ? "-10000000000000" : "0",
            onChange: undefined,
            onFocus: undefined,
            onBlur: undefined,
            onKeyPress: undefined,
            onKeyUp: undefined,
            onKeyDown: undefined,
            watchExternalChanges: false,
          } as AutoNumericOptions),
        );
      }
    },
    [allowNegativeValue, initialValue],
  );

  const {
    onChange,
    onFocus,
    onBlur,
    onKeyPress,
    onKeyUp,
    onKeyDown,
    InputProps,
    inputProps,
    InputLabelProps,
    ...otherProps
  } = restProps;

  const eventHandlers = {
    onChange,
    onFocus,
    onBlur,
    onKeyPress,
    onKeyUp,
    onKeyDown,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callEventHandler = <HandlerName extends keyof typeof eventHandlers>(handlerName: HandlerName, event: any) => {
    const handlerFunc = eventHandlers[handlerName];
    if (handlerFunc) {
      handlerFunc(event, getInputValue());
    }
  };

  return (
    <Box>
      <Box>
        {label && (
          <Typography variant="body3" color={disabled ? theme.palette.text.disabled : theme.palette.text.primary}>
            {label} *
          </Typography>
        )}
        {HelperModal}
      </Box>
      <TextField
        inputRef={ref}
        onChange={(e) => callEventHandler("onChange", e)}
        onFocus={(e) => callEventHandler("onFocus", e)}
        onBlur={(e) => callEventHandler("onBlur", e)}
        onKeyPress={(e) => callEventHandler("onKeyPress", e)}
        onKeyUp={(e) => callEventHandler("onKeyUp", e)}
        onKeyDown={(e) => callEventHandler("onKeyDown", e)}
        inputProps={inputProps}
        InputProps={{
          startAdornment: currencyCode ? <InputAdornment position="start">{currencySymbol}</InputAdornment> : null,
          ...InputProps,
        }}
        label=""
        sx={{
          mt: 1,
          "& .MuiOutlinedInput-root": {
            "&.Mui-disabled fieldset": {
              borderColor: "action.disabledBackground",
            },
          },
        }}
        InputLabelProps={{ shrink: false, ...InputLabelProps }}
        disabled={disabled}
        {...otherProps}
      />
    </Box>
  );
};

/** Props passed to initialize a {@link CurrencyField} component */
export type CurrencyFieldProps = StandardCurrencyInputProps | FilledCurrencyInputProps | OutlinedCurrencyInputProps;

interface TextFieldPropOverrides {
  /** Allow negative values. Defaults to false */
  allowNegativeValue?: boolean;
  /** Value of the amount entered, expressed as an integer number of the currency's smallest denomination */
  value?: number | null;
  /** Lower-case currency code of the amount */
  currencyCode?: string;
  /** Default value of the field when uncontrolled */
  defaultValue?: number | null;
  onChange?: CurrencyFieldEventHandler<React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>;
  onFocus?: CurrencyFieldEventHandler<React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>>;
  onBlur?: CurrencyFieldEventHandler<React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>>;
  onKeyUp?: CurrencyFieldEventHandler<React.KeyboardEvent<HTMLDivElement>>;
  onKeyDown?: CurrencyFieldEventHandler<React.KeyboardEvent<HTMLDivElement>>;
  HelperModal?: JSX.Element | null;
}

type CurrencyFieldEventHandler<TEvent> = (event: TEvent, value: number | null) => void;

export type StandardCurrencyInputProps = Omit<StandardTextFieldProps, keyof TextFieldPropOverrides> &
  TextFieldPropOverrides;

export type FilledCurrencyInputProps = Omit<FilledTextFieldProps, keyof TextFieldPropOverrides> &
  TextFieldPropOverrides;

export type OutlinedCurrencyInputProps = Omit<OutlinedTextFieldProps, keyof TextFieldPropOverrides> &
  TextFieldPropOverrides;

export default CurrencyField;
