import { gql } from "@apollo/client";

export const walletStatusFragment = gql`
  fragment walletStatusFragment on financial_wallet_current_status {
    automaticReloadEnabled: automatic_reload_enabled
    balance: current_balance
  }
`;

export interface WalletStatus {
  automaticReloadEnabled: boolean;
  balance: number;
}

export const walletFragment = gql`
  fragment walletFragment on financial_wallet {
    accountId: account_id
    ledgerAccountId: ledger_account_id
    gatewayName: gateway_name
    gatewayAccountId: gateway_account_id
    desiredWalletBalance: desired_wallet_balance
    minWalletBalance: min_wallet_balance
    status: current_status {
      ...walletStatusFragment
    }
  }
  ${walletStatusFragment}
`;

export interface Wallet {
  accountId: string;
  ledgerAccountId: string;
  gatewayName: string;
  gatewayAccountId: string;
  minWalletBalance: number;
  desiredWalletBalance: number;
  status: WalletStatus;
}
