import { gql } from "@apollo/client";
import {
  EventPolicyComplianceDetail,
  eventPolicyComplianceDetailFragment,
} from "common/graphql/fragments/eventPolicyComplianceDetailFragment";

export const eventPolicyComplianceFragment = gql`
  fragment eventPolicyComplianceFragment on event_policy_compliance {
    price
    details(order_by: [{ rate_start_at: asc }]) {
      ...eventPolicyComplianceDetailFragment
    }
  }
  ${eventPolicyComplianceDetailFragment}
`;

export interface EventPolicyCompliance {
  id: string;
  price: number;
  details: EventPolicyComplianceDetail[];
}
