import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TermsOfService from "@app.automotus.io/components/TermsOfService";

export const TermsOfServiceWrapper: React.FC<TermsOfServiceWrapperProps> = ({
  variant,
  onBack,
  onAccept,
  onDecline,
}) => {
  const isDisplayMode = variant === "display";

  return (
    <Box sx={{ position: "relative", px: { xs: 0, lg: 2 }, pt: { xs: 2, tiny: 3, lg: 3 } }}>
      <TermsOfService TitleTypographyProps={{ sx: { fontWeight: 500, fontSize: { xs: "16px", lg: "34px" } } }} />
      <Box sx={{ position: "sticky", bottom: 0, backgroundColor: "white" }} pt={2}>
        {isDisplayMode ? (
          <Button variant="contained" onClick={onBack} fullWidth sx={{ mt: 2, mb: 4, textTransform: "none" }}>
            Back
          </Button>
        ) : (
          <Grid container direction="row" alignItems="space-between" xs={12} ml={0} pb={{ xs: 2, lg: 0 }}>
            <Grid item xs={6} pr={1}>
              <Button
                variant="outlined"
                onClick={onDecline}
                fullWidth
                sx={{
                  textTransform: "unset",
                  py: { xs: 0.5, tiny: 0.75, lg: 1 },
                  fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                  fontWeight: 500,
                }}
              >
                Decline
              </Button>
            </Grid>
            <Grid item xs={6} pl={1}>
              <Button
                variant="contained"
                onClick={onAccept}
                sx={{
                  textTransform: "unset",
                  py: { xs: 0.5, tiny: 0.75, lg: 1 },
                  fontSize: { xs: "14px", tiny: "16px", lg: "18px" },
                  height: { xs: "30px", tiny: "36px", lg: "42px" },
                  fontWeight: 500,
                }}
                fullWidth
              >
                Accept
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default TermsOfServiceWrapper;

export interface TermsOfServiceWrapperProps {
  /** Event handler that gets triggered when the user clicks Back button */
  onBack?: () => void;
  /** Event handler that gets triggered when the user clicks Accept button */
  onAccept?: () => void;
  /** Event handler that gets triggered when the user clicks Decline button */
  onDecline?: () => void;
  /** This indicates whether the component is in display mode or select mode. Display mode is only used in landing page, and select mode is used in CurbPass Setup page */
  variant: "display" | "select";
}
