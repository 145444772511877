import { gql } from "@apollo/client";

export const PAY_BILL_SEARCH_INVOICE = gql`
  mutation PayBillSearchInvoice($invoiceNumber: Int!, $licensePlateNumber: String!, $licensePlateState: String!) {
    searchResult: pay_bill_search_invoice(
      invoice_number: $invoiceNumber
      license_plate_number: $licensePlateNumber
      license_plate_state: $licensePlateState
    ) {
      authToken: auth_token
      invoiceId: invoice_id
      registeredVehicleId: registered_vehicle_id
      code
    }
  }
`;

export interface PayBillSearchInvoiceVars {
  invoiceNumber: number;
  licensePlateNumber: string;
  licensePlateState: string;
}

export interface PayBillSearchInvoiceData {
  searchResult: {
    authToken: string;
    invoiceId: string;
    registeredVehicleId: string;
    code: "ClaimedNewInvoiceVehicleAndAccount" | "ClaimedNewInvoiceAndVehicle" | "NoActionTaken";
  };
}
