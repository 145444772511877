import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import formatDate from "date-fns/format";
import Stack from "@mui/material/Stack";
import SessionDetailsItem from "@app.automotus.io/components/SessionDetailsItem";

/**
 * Displays basic details about a park event for a payee user.
 */
export const SessionDetails: React.FC<SessionDetailsProps> = ({
  loading,
  startTime,
  endTime,
  addressState,
  addressCity,
  addressStreet,
  licensePlateNumber,
  licensePlateStateCode,
}) => (
  <Paper sx={{ p: 3 }}>
    <Stack spacing={3}>
      <Typography variant={"h5"}>Park Event Details</Typography>
      <Grid container>
        <SessionDetailsItem
          loading={loading}
          title={"Date"}
          content={endTime && formatDate(endTime, "M/d/yyyy")}
          skeletonWidth={104}
        />
        <SessionDetailsItem
          loading={loading}
          title={"Time"}
          content={startTime && endTime && `${formatDate(startTime, "hh:mm a")} - ${formatDate(endTime, "hh:mm a")}`}
          skeletonWidth={166}
        />
        <SessionDetailsItem loading={loading} title={"Address"} content={addressStreet} skeletonWidth={111} />
        <SessionDetailsItem
          loading={loading}
          title={"City"}
          content={addressCity && addressState && `${addressCity}, ${addressState}`}
          skeletonWidth={112}
        />
        <SessionDetailsItem
          loading={loading}
          title={"Vehicle"}
          content={`${licensePlateStateCode} ${licensePlateNumber}`}
          skeletonWidth={104}
        />
      </Grid>
    </Stack>
  </Paper>
);

/** Props passed to initialize a {@link SessionDetails} component */
export interface SessionDetailsProps {
  /** Loading status of component */
  loading?: boolean;
  /** Start time of event. Must be defined if component is loaded */
  startTime?: Date;
  /** End time of event. Must be defined if component is loaded */
  endTime?: Date;
  /** Street address where event occurred. Must be defined if component is loaded */
  addressStreet?: string;
  /** City where event occurred. Must be defined if component is loaded */
  addressCity?: string;
  /** State where event occurred. Must be defined if component is loaded */
  addressState?: string;
  /** License plate number */
  licensePlateNumber?: string;
  /** License plate state code */
  licensePlateStateCode?: string;
}

export default SessionDetails;
