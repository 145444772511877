import React from "react";
import Box, { BoxProps } from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import CONTENTS from "./contents";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const defaultProps = {
  TitleTypographyProps: {
    variant: "h5" as const,
    sx: {
      mb: 3,
    },
  },
  BodyTypographyProps: {
    variant: "body2" as const,
    sx: {
      whiteSpace: "pre-line" as const,
      height: "100%",
      overflow: "hidden",
    },
  },
};

/**
 * Component that renders the application's terms of service (along with the title thereof).
 * Provides an interface for the user to override the styles of the containing Box,
 * the title text, and the body text.
 */
export const TermsOfService: React.FC<TermsOfServiceProps> = ({
  TitleTypographyProps,
  BodyTypographyProps,
  ...boxProps
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ overflowY: "auto" }} {...boxProps}>
      {!isMobile && (
        <Typography
          {...TitleTypographyProps}
          sx={{ ...defaultProps.TitleTypographyProps.sx, ...TitleTypographyProps?.sx }}
        >
          Terms of Service
        </Typography>
      )}
      <Typography {...BodyTypographyProps} sx={{ ...defaultProps.BodyTypographyProps.sx, ...BodyTypographyProps?.sx }}>
        {CONTENTS}
      </Typography>
    </Box>
  );
};

TermsOfService.defaultProps = defaultProps;

/** Props passed to initialize a {@link TermsOfService} component */
export interface TermsOfServiceProps extends BoxProps {
  /** Props passed to the title Typography component */
  TitleTypographyProps?: TypographyProps;
  /** Props passed to the body Typography component */
  BodyTypographyProps?: TypographyProps;
}

export default TermsOfService;
