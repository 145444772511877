import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { formatCurrency } from "common/format";
import LoadingSkeleton from "../../LoadingSkeleton";
import { convertDateToString, convertTimeToString } from "common/format";
import { TransactionPaymentData } from "../TransactionPayments";
import AccessTimeRounded from "@mui/icons-material/AccessTimeRounded";
import DirectionsCar from "@mui/icons-material/DirectionsCar";
import AttachMoneyOutlined from "@mui/icons-material/AttachMoneyOutlined";

const TransactionStatus = ({ violation, status }: { violation?: boolean; status: string }) => {
  const statusString = status === "under_review" ? "Under Review" : violation ? "Violation" : "";
  return statusString ? (
    <Box
      py={0.5}
      pr={2}
      sx={{ justifyContent: "flex-end", display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}
    >
      <Typography variant="body4" color={statusString === "Violation" ? "error.main" : "text.primary"}>
        {statusString}
      </Typography>
    </Box>
  ) : null;
};

const CardStatement = ({ data }: { data: TransactionPaymentData }) => {
  const { gatewayTransactionType } = data;
  if (gatewayTransactionType === "initial_wallet_load") {
    return (
      <Typography color="text.secondary" sx={{ textAlign: "center", fontSize: 12 }}>
        You added ${formatCurrency(data.balance || 0)} to your digital wallet.
      </Typography>
    );
  } else {
    return (
      <Typography color="text.secondary" sx={{ textAlign: "center", fontSize: 12 }}>
        Your payment method was charged for ${formatCurrency(Math.abs(data.amount))} to refill your digital wallet as
        your balance was low.
      </Typography>
    );
  }
};

export const TransactionPaymentCardView: React.FC<DepositLogCardViewProps> = ({ loading = false, data }) => {
  if (!data) {
    return null;
  }

  const isTransactionStatement = !!data.gatewayTransactionType;

  return !isTransactionStatement ? (
    <LoadingSkeleton width="100%" loading={loading}>
      <Paper>
        <TransactionStatus violation={data.violation} status={data.status} />
        <Box p={2} sx={{ display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}>
          <AttachMoneyOutlined sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          <Box sx={{ ml: 2, width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">{data.index}</Typography>
              <Typography variant="h7" sx={{ fontWeight: 500 }} color={data.violation ? "error.main" : "text.primary"}>
                {`${data.amount > 0 ? "-" : ""}$${formatCurrency(data.amount)}`}
              </Typography>
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between", mt: 1 }}>
              <Typography variant="body4" sx={{ fontWeight: 300 }}>
                {convertDateToString(data.date)}
              </Typography>
              <Typography variant="body4" color="text.secondary">
                Bal. {`$${formatCurrency(data.balance || 0)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2} sx={{ display: "flex", borderBottom: "1px solid #EEEEEE", color: "text.primary" }}>
          <AccessTimeRounded sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          <Box sx={{ ml: 2, width: "100%" }}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
              <Typography variant="body1">{convertDateToString(data.date)}</Typography>
              <Typography variant="body1">{`${data.amount > 0 ? "-" : ""}$${formatCurrency(data.amount)}`}</Typography>
            </Box>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "space-between", mt: 1, color: "text.secondary" }}
            >
              <Typography variant="body4" sx={{ fontWeight: 400 }}>
                {convertTimeToString(data.startTime)} to {convertTimeToString(data.endTime)}
              </Typography>
              {/* TODO: Implement Refund Link */}
              <Typography variant="body4" sx={{ fontWeight: 400 }}>
                Re: ASDF
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box p={2} sx={{ display: "flex", color: "text.secondary" }}>
          <DirectionsCar sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          <Box sx={{ ml: 2, width: "100%" }}>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: data.violation ? "space-between" : "flex-start" }}
            >
              <Typography variant="body1">{data.vehicle}</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </LoadingSkeleton>
  ) : (
    <CardStatement data={data} />
  );
};

export interface DepositLogCardViewProps {
  loading?: boolean;
  data?: TransactionPaymentData;
}

export default TransactionPaymentCardView;
