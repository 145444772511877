import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { TermsOfServiceWrapper } from "@app.automotus.io/components/TermsOfService";
import { useMutation } from "@apollo/client";
import useUserProfile from "@app.automotus.io/components/hooks/useUserProfile";
import {
  ACCEPT_TERMS_OF_SERVICE,
  DECLINE_TERMS_OF_SERVICE,
  UpdateTermsOfServiceData,
  UpdateTermsOfServiceAcceptVars,
  UpdateTermsOfServiceDeclineVars,
} from "common/graphql/mutations";

export const SignUpTermsOfService: React.FC = () => {
  const navigate = useNavigate();
  const state = useLocation().state as Record<string, unknown>;
  const redirectUrl = (state && state.sourceRoute ? state.sourceRoute : "/signup/profile") as string;
  const isFromLandingPage = state.isFromLandingPage;
  const [acceptTermsOfService] = useMutation<UpdateTermsOfServiceData, UpdateTermsOfServiceAcceptVars>(
    ACCEPT_TERMS_OF_SERVICE,
  );
  const [declineTermsOfService] = useMutation<UpdateTermsOfServiceData, UpdateTermsOfServiceDeclineVars>(
    DECLINE_TERMS_OF_SERVICE,
  );
  const { userProfile } = useUserProfile();

  const onAcceptTOS = async () => {
    await acceptTermsOfService({
      variables: {
        userId: userProfile?.id || "",
        tosAcceptedVersion: "2022-03-01",
      },
    });
    navigate(redirectUrl, {
      state: {
        ...(state as Record<string, unknown>),
        isAgreedToTermsOfService: true,
      },
    });
  };

  const onDeclineTOS = async () => {
    await declineTermsOfService({
      variables: {
        userId: userProfile?.id || "",
      },
    });
    navigate(redirectUrl, {
      state: {
        ...(state as Record<string, unknown>),
        isAgreedToTermsOfService: false,
      },
    });
  };

  return (
    <TermsOfServiceWrapper
      variant={isFromLandingPage ? "display" : "select"}
      onBack={() => {
        navigate(redirectUrl, { state });
      }}
      onAccept={onAcceptTOS}
      onDecline={onDeclineTOS}
    />
  );
};

export default SignUpTermsOfService;
