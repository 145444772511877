import React, { useState } from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  RemovePaymentMethodForm,
  RemovePaymentMethodFormSubmitFunc,
} from "@app.automotus.io/components/scenes/Account/PaymentSection/RemovePaymentMethodForm";
import Content from "@app.automotus.io/components/scenes/Account/PaymentSection/PaymentSectionDialog/Content";
import {
  UpdatePaymentMethodForm,
  UpdatePaymentMethodFormSubmitFunc,
} from "@app.automotus.io/components/scenes/Account/PaymentSection/UpdatePaymentMethodForm";
import { usePaymentsHelpers } from "../../../../hooks/usePaymentsHelpers";
import {
  AddPaymentMethodForm,
  AddPaymentMethodFormSubmitFunc,
} from "@app.automotus.io/components/scenes/Account/PaymentSection/AddPaymentMethodForm/AddPaymentMethodForm";
import { CustomerPaymentProfile } from "api/adaptors/AuthorizeNet";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import ALERTS from "../alerts";
import {
  WalletRefundRequestModal,
  WalletRefundRequestModalProps,
} from "@app.automotus.io/components/modals/WalletRefundRequestModal";
import { WalletRefundConfirmationModal } from "@app.automotus.io/components/modals/WalletRefundConfirmationModal";
import { BottomSheet } from "@app.automotus.io/components/BottomSheet";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { useMutation } from "@apollo/client";
import { useIsMobile } from "@app.automotus.io/components/hooks";
import {
  REQUEST_WALLET_REFUND,
  RequestWalletRefundData,
  RequestWalletRefundVars,
} from "common/graphql/mutations/RequestWalletRefund";
import DialogContent from "@mui/material/DialogContent";

export const PaymentSectionDialog: React.FC<PaymentSectionDialogProps> = ({
  mode,
  onClose = () => undefined,
  onExited = () => undefined,
  paymentMethod,
  redirected = false,
  ...dialogProps
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { publishSnackbarMessage } = useSnackPackContext();

  // TODO: handle client error
  const [{ addPaymentMethodCard, removePaymentMethod, updatePaymentMethodCard }, { clientLoading }] =
    usePaymentsHelpers();

  const handleSubmit = async (
    input:
      | Parameters<UpdatePaymentMethodFormSubmitFunc>[0]
      | Parameters<RemovePaymentMethodFormSubmitFunc>[0]
      | Parameters<AddPaymentMethodFormSubmitFunc>[0],
  ) => {
    try {
      if (input.action === "add") {
        await addPaymentMethodCard({ ...input, makeDefault: false });
      } else if (input.action === "update") {
        await updatePaymentMethodCard(input);
      } else if (input.action === "remove") {
        await removePaymentMethod(input);
      }

      publishSnackbarMessage(ALERTS.success[input.action]);
      onClose();
    } catch (err) {
      console.error(err);
      publishSnackbarMessage(ALERTS.failure[input.action]);
    }
  };

  return !isMobile || mode !== "request_refund" ? (
    <Dialog
      {...dialogProps}
      fullScreen={fullScreen}
      onBackdropClick={onClose}
      TransitionProps={{ onExited }}
      PaperProps={{
        sx: { borderRadius: fullScreen ? 0 : 1.5, minWidth: { lg: 454 } },
      }}
    >
      <Content
        title={
          mode === "remove"
            ? "Remove Payment Method"
            : mode === "add"
            ? "Add a Payment Method"
            : mode === "update"
            ? "Update Payment Method"
            : ""
        }
        onClose={onClose}
      >
        {mode === "remove" ? (
          <RemovePaymentMethodForm
            loading={clientLoading}
            paymentMethod={paymentMethod}
            onSubmit={handleSubmit}
            onClose={onClose}
          />
        ) : mode === "add" ? (
          <AddPaymentMethodForm loading={clientLoading} onSubmit={handleSubmit} onClose={onClose} />
        ) : mode === "update" ? (
          <UpdatePaymentMethodForm
            loading={clientLoading}
            onSubmit={handleSubmit}
            paymentMethod={paymentMethod}
            onClose={onClose}
          />
        ) : mode === "request_refund" ? (
          <WalletRefundRequestModalWithQuery onClose={onClose} redirected={redirected} />
        ) : null}
      </Content>
    </Dialog>
  ) : (
    <BottomSheet open={dialogProps.open} onClose={onClose} marginTopSmall={56} marginTopMedium={64}>
      <AppBar
        sx={{
          height: "52px",
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
          <Box>
            <Typography variant="body3" sx={{ fontWeight: 500, ml: 1 }}>
              Refund Request
            </Typography>
          </Box>
          <Box>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <Box
                sx={{
                  width: "28px",
                  height: "28px",
                  borderRadius: "100px",
                  backgroundColor: "rgba(0, 0, 0, 0.135)",
                }}
              >
                <CloseIcon sx={{ fontSize: "14px" }} />
              </Box>
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ px: 3, pt: 0, mb: -2.5 }}>
        <WalletRefundRequestModalWithQuery onClose={onClose} redirected={redirected} />
      </DialogContent>
    </BottomSheet>
  );
};

const WalletRefundRequestModalWithQuery: React.FC<{ onClose: () => void; redirected: boolean }> = ({
  onClose,
  redirected,
}) => {
  const { userProfile, loading } = useUserProfile();
  // TODO:
  const [requestRefund] = useMutation<RequestWalletRefundData, RequestWalletRefundVars>(REQUEST_WALLET_REFUND);
  const [submittedRequest, setSubmittedRequest] = useState(false);
  const { publishSnackbarMessage } = useSnackPackContext();
  const isMobile = useIsMobile();
  const handleSubmit: WalletRefundRequestModalProps["onSubmit"] = async (values, { setSubmitting }) => {
    try {
      await requestRefund({
        variables: {
          walletLedgerAccountId: userProfile?.account?.wallets?.[0]?.ledgerAccountId || "",
          refundAmount: values.amount || 0,
        },
      });
      setSubmittedRequest(true);
    } catch (err) {
      console.error(err);
      publishSnackbarMessage({ message: "Failed to request refund. Please try again.", severity: "error" });
    } finally {
      setSubmitting(false);
    }
  };

  return submittedRequest ? (
    <WalletRefundConfirmationModal email={userProfile?.email || ""} onClose={onClose} />
  ) : (
    <WalletRefundRequestModal
      loading={loading}
      walletBalance={(userProfile?.account?.wallets?.[0]?.status?.balance || 0) / 100}
      onClose={onClose}
      title={!isMobile ? "Refund Request" : ""}
      onSubmit={handleSubmit}
      redirected={redirected}
    />
  );
};

export interface PaymentSectionDialogProps extends Pick<DialogProps, "open" | "onBackdropClick"> {
  mode: "add" | "remove" | "update" | "request_refund" | "refund_request_submitted" | null;
  onClose?: () => void;
  onExited?: () => void;
  paymentMethod?: CustomerPaymentProfile;
  redirected?: boolean;
}

export default PaymentSectionDialog;
