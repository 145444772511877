import React from "react";
import DashboardContentLayout from "@app.automotus.io/components/Layout/DashboardContentLayout";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import formatDate from "date-fns/format";
import TransactionSummaryTitle from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionSummary/common/TransactionSummaryTitle";
import { useIsMobile } from "@app.automotus.io/components/hooks";

const displayDate = (d: Date) => formatDate(d, "M/d/yyyy");

/**
 * Component that displays the transaction summary for a payer account type.
 */
export const PayerTransactionSummary: React.FC<PayerTransactionSummaryProps> = ({
  now = new Date(),
  accountHolderName,
  loading,
  currencySymbol = "$",
  digitalWalletBalanceAmount = 0,
  availableBalanceAmount = 0,
  chargesTodayAmount = 0,
  lastWalletDepositAmount = 0,
  lastWalletDepositDate = new Date(),
  // onClickAddFunds = () => undefined,
}) => {
  const isMobile = useIsMobile();
  const isDesktop = !isMobile;

  const standardTitleVariant = isDesktop ? "subtitle1" : "body2";
  const boldTitleVariant = isDesktop ? "h7" : "subtitle1";

  const standardContentVariant = isDesktop ? "h6" : "body2";
  const boldContentVariant = isDesktop ? "h5" : "subtitle1";

  const itemTitleSkeleton = <Skeleton width="14ch" />;
  const itemContentSkeleton = <Skeleton width="8ch" />;

  return (
    <Box sx={{ bgcolor: "background.default" }}>
      <DashboardContentLayout sx={{ my: { xs: 4, lg: 8 } }}>
        <TransactionSummaryTitle loading={loading} accountHolderName={accountHolderName} />
        <Grid container justifyContent="space-between" rowSpacing={{ xs: 1.5 }}>
          <Grid container item xs={12} lg={6} rowSpacing={{ xs: 1.5, lg: 2.5 }}>
            <SummaryItemLayout
              alignContent={isDesktop ? "left" : "right"}
              titleElement={
                <Typography variant={boldTitleVariant}>{loading ? itemTitleSkeleton : "Remaining Balance:"}</Typography>
              }
              contentElement={
                <Typography variant={boldContentVariant}>
                  {loading ? itemContentSkeleton : formatCents(currencySymbol, availableBalanceAmount)}
                </Typography>
              }
            />
            <SummaryItemLayout
              alignContent={isDesktop ? "left" : "right"}
              titleElement={
                <Typography variant={standardTitleVariant}>
                  {loading ? itemTitleSkeleton : "Digital Wallet:"}
                </Typography>
              }
              contentElement={
                <Typography variant={standardContentVariant} align={isDesktop ? "left" : "right"}>
                  {loading ? itemContentSkeleton : formatCents(currencySymbol, digitalWalletBalanceAmount)}
                </Typography>
              }
            />
          </Grid>
          <Grid container item xs={12} lg={6} rowSpacing={{ xs: 1.5, lg: 2.5 }}>
            <SummaryItemLayout
              alignTitle="right"
              alignContent="right"
              titleElement={
                <Typography variant={boldTitleVariant} align={isDesktop ? "right" : "left"}>
                  {loading ? itemTitleSkeleton : `Charges Today (${displayDate(now)}):`}
                </Typography>
              }
              contentElement={
                <Typography variant={isDesktop ? "h5" : "body2"} align="right">
                  {loading ? itemContentSkeleton : formatCents(currencySymbol, chargesTodayAmount)}
                </Typography>
              }
            />
            <SummaryItemLayout
              alignTitle={isDesktop ? "right" : "left"}
              alignContent="right"
              titleElement={
                <Typography variant={isDesktop ? "subtitle1" : "body2"} align={isDesktop ? "right" : "left"}>
                  {loading
                    ? itemTitleSkeleton
                    : `Latest Deposit (${lastWalletDepositDate ? displayDate(lastWalletDepositDate) : "None"}):`}
                </Typography>
              }
              contentElement={
                <>
                  <Typography variant={isDesktop ? "h6" : "body2"} align="right">
                    {loading ? itemContentSkeleton : formatCents(currencySymbol, lastWalletDepositAmount)}
                  </Typography>
                </>
              }
            />
          </Grid>
        </Grid>
        {/*{loading ? (*/}
        {/*  <Skeleton width={isDesktop ? undefined : "100%"}>*/}
        {/*    <Button sx={{ mt: 2 }} />*/}
        {/*  </Skeleton>*/}
        {/*) : (*/}
        {/*  <Button sx={{ mt: 2 }} variant="contained" fullWidth={isMobile} onClick={onClickAddFunds} disabled={loading}>*/}
        {/*    Add Funds*/}
        {/*  </Button>*/}
        {/*)}*/}
      </DashboardContentLayout>
    </Box>
  );
};

const SummaryItemLayout: React.FC<SummaryItemLayoutProps> = ({
  titleElement,
  contentElement,
  alignTitle = "left",
  alignContent = "left",
}) => {
  return (
    <Grid container item xs={12} rowSpacing={1.5} justifyContent="space-between">
      <Grid item container xs="auto" lg={12} justifyContent={alignTitle === "left" ? "flex-start" : "flex-end"}>
        <Grid item>{titleElement}</Grid>
      </Grid>
      <Grid item container xs="auto" lg={12} justifyContent={alignContent === "left" ? "flex-start" : "flex-end"}>
        <Grid item>{contentElement}</Grid>
      </Grid>
    </Grid>
  );
};

interface SummaryItemLayoutProps {
  alignTitle?: "left" | "right";
  alignContent?: "left" | "right";
  titleElement: JSX.Element;
  contentElement: JSX.Element;
}

function formatCents(currencySymbol: string, cents: number): string {
  return `${currencySymbol}${(cents / 100).toFixed(2)}`;
}

export interface PayerTransactionSummaryValues {
  now?: Date;
  accountHolderName?: string;
  currencySymbol?: string;
  availableBalanceAmount?: number;
  digitalWalletBalanceAmount?: number;
  chargesTodayAmount?: number;
  lastWalletDepositDate?: Date;
  lastWalletDepositAmount?: number;
}

/** Props passed to initialize a {@link PayerTransactionSummary} component */
export interface PayerTransactionSummaryProps {
  /** Current date */
  now?: Date;
  /** Name of account holder */
  accountHolderName?: string;
  /** Currency symbol to display. Defaults to "$" */
  currencySymbol?: string;
  /** Loading status */
  loading: boolean;
  /** Available balance amount in cents */
  availableBalanceAmount?: number;
  /** Desired wallet balance in cents */
  digitalWalletBalanceAmount?: number;
  /** Charged amount on the current day in cents */
  chargesTodayAmount?: number;
  /** Last wallet deposit date */
  lastWalletDepositDate?: Date | null;
  /** Last wallet deposit amount */
  lastWalletDepositAmount?: number;
  /** Click handler fired when a user clicks the Add Funds button */
  onClickAddFunds?: React.MouseEventHandler<HTMLButtonElement>;
}

export default PayerTransactionSummary;
