import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TransactionInformation from "@app.automotus.io/components/Dashboard/TransactionDetail/TransactionInformation/TransactionInformation";
import DisputeForm, { DisputeFormValues } from "@app.automotus.io/components/forms/DisputeForm/DisputeForm";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import {
  GET_TRANSACTION_DETAIL,
  GetTransactionDetailData,
  GetTransactionDetailVars,
} from "common/graphql/queries/GetTransactionDetail";
import { DisputeSuccessfullySubmittedModal } from "@app.automotus.io/components/DisputeSuccessfullySubmittedModal";
import { useUserProfile } from "@app.automotus.io/components/hooks";
import { FormikHelpers } from "formik";
import { useAtmtsRest } from "@app.automotus.io/components/hooks/useAtmtsRest";
import { useSnackPackContext } from "@app.automotus.io/components/context/SnackPack";
import sumBy from "lodash/sumBy";

export const DisputeTransaction: React.FC = () => {
  const { transactionId = "" } = useParams<{ transactionId: string }>();
  const navigate = useNavigate();
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [disputeTicketNumber, setDisputeTicketNumber] = useState("");
  const { postTransactionDispute } = useAtmtsRest();
  const { publishSnackbarMessage } = useSnackPackContext();

  // TODO: handle errors from these requests
  const { userProfile, loading: userProfileLoading } = useUserProfile();
  const { data: { transaction } = {}, loading: transactionLoading } = useQuery<
    GetTransactionDetailData,
    GetTransactionDetailVars
  >(GET_TRANSACTION_DETAIL, {
    variables: { transactionId },
  });

  const loading = userProfileLoading || transactionLoading;

  const handleSubmit = async (values: DisputeFormValues, { setSubmitting }: FormikHelpers<DisputeFormValues>) => {
    try {
      const { data } = await postTransactionDispute(transactionId, {
        disputedStartTime: values.disputedStartTime || new Date(0),
        disputedEndTime: values.disputedEndTime || new Date(0),
        description: values.comment,
      });

      setDisputeTicketNumber(data.disputeTicketNumber);
    } catch (err) {
      console.error(err);
      // TODO: prevent retry when information invalid (request responds with 404 or 401)
      publishSnackbarMessage({
        message: "Your dispute could not be submitted. Please try again.",
        severity: "error",
        autoHideDuration: 6e3,
      });
    } finally {
      setSubmitting(false);
    }
  };

  const transactionEvent = transaction?.park || transaction?.doublePark;
  const transactionStartDate = new Date(transactionEvent?.startTime || 0);
  const transactionEndDate = new Date(transactionEvent?.endTime || 0);
  // TODO: internationalize currency symbol
  const currencySymbol = "$";
  const licensePlateNumber = transaction?.registeredVehicle.licensePlate || "";
  const licensePlateStateCode = transaction?.registeredVehicle.stateCode || "";
  const address = transaction?.addressStreet || "";
  // TODO: get city where event occurred
  const city = "Pittsburgh, PA";
  const transactionAmount = transactionEvent?.policyRateDetails
    ? sumBy(transactionEvent?.policyRateDetails, "price")
    : 0;

  return (
    <Box>
      <Box mt={3}>
        <Box sx={{ mb: { xs: 2, tiny: 2, sm: 2, md: 3 } }}>
          <Typography variant="h7">Summary</Typography>
        </Box>
        <TransactionInformation
          loading={loading}
          transaction={{
            transactionStartDate,
            transactionEndDate,
            currencySymbol,
            licensePlateNumber,
            licensePlateStateCode,
            address,
            city,
            transactionAmount,
          }}
        />
      </Box>
      <Box sx={{ mt: { xs: 3.5, tiny: 3.5, sm: 3.5, md: 5 } }}>
        <Typography variant="h7" mb={3.5} sx={{ display: { xs: "none", tiny: "none", sm: "none", md: "block" } }}>
          Dispute Information
        </Typography>
        <DisputeForm
          loading={loading}
          onSubmit={handleSubmit}
          initialValues={{
            disputeType: "full",
            disputedStartTime: new Date(transactionEvent?.startTime || 0),
            disputedEndTime: new Date(transactionEvent?.endTime || 0),
            comment: "",
          }}
        />
      </Box>
      <DisputeSuccessfullySubmittedModal
        disputeTicketId={disputeTicketNumber}
        email={userProfile?.email || ""}
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        onClickViewDisputeStatus={() => navigate(".")}
        onClickReturnToSummary={() => navigate("..")}
      />
    </Box>
  );
};
