import { makeVar } from "@apollo/client";
import { PaymentMethod } from "common/graphql";

export interface WalletSetupState {
  walletBalance: number;
  paymentMethod: PaymentMethod;
}

export interface CityLocationInfo {
  cityName: string;
  payeeAccountId: string;
}

export const walletSetupStateVar = makeVar<WalletSetupState | null>(null);

export const cityLocationInfoVar = makeVar<CityLocationInfo | null>(null);
