import React from "react";
import { ParkingHourIntervalDescriptor } from "@app.automotus.io/components/scenes/PartnerLandingPage/PartnerLandingPageParkingRatesAndHoursModal/PartnerLandingPageParkingRatesAndHoursModal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import { formatHourAmPm } from "common/format";

export const ParkingHoursTabContent: React.FC<PartnerLandingPageParkingHoursModalContentProps> = ({
  parkingHours,
  daysOfWeek = ["Mon", "Fri"],
}) => {
  const theme = useTheme();
  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"caption"} color={theme.palette.text.secondary}>
          Location
        </Typography>
        <Typography variant={"caption"} color={theme.palette.text.secondary} sx={{ float: "right" }}>
          {daysOfWeek[0]} to {daysOfWeek[daysOfWeek.length - 1]}
        </Typography>
      </Box>
      <Divider sx={{ width: "150%", ml: "-20%" }} />
      <Box sx={{ height: "194px", overflowX: "hidden", mr: "-12px", pr: "12px" }}>
        {parkingHours.map(({ startHour, endHour, location }) => (
          <Grid container key={location} display={"flex"} justifyContent={"space-between"} sx={{ py: 0.75 }}>
            <Grid item xs={6}>
              <Typography variant={"body1"}>{location}</Typography>
            </Grid>
            <Grid item xs={6} display={"flex"} justifyContent={"flex-end"}>
              <Typography variant={"body1"} align={"right"}>
                {formatHourAmPm(startHour)} to {formatHourAmPm(endHour)}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export interface PartnerLandingPageParkingHoursModalContentProps {
  parkingHours: ParkingHourIntervalDescriptor[];
  daysOfWeek?: string[]; // Only the first and the last index of the arry are displayed
}

export default ParkingHoursTabContent;
