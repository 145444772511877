import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CarouselLeftButton from "@app.automotus.io/components/icons/CarouselLeftButton";
import CarouselRightButton from "@app.automotus.io/components/icons/CarouselRightButton";
import LoadingSkeleton from "@app.automotus.io/components/LoadingSkeleton";
import Skeleton from "@mui/lab/Skeleton";
import { convertTimeToString } from "common/format";

export const TransactionImageCarouselItem: React.FC<TransactionImageCarouselItemProps> = ({
  date,
  title,
  imageSrc,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const onLoad = () => {
    setLoading(false);
  };

  return (
    <LoadingSkeleton loading={loading} width="100%" height="100%">
      <Box sx={{ width: "100%", height: "100%", position: "relative" }}>
        <img src={imageSrc} style={{ width: "100%", height: "100%" }} onLoad={onLoad} />
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 10.18%, rgba(0, 0, 0, 0.50) 100%)",
          }}
        />
        <Box sx={{ position: "absolute", top: [8, 8, 8, 24], left: [14, 14, 14, 16] }}>
          <Typography
            color="white"
            fontSize={["1rem", "1rem", "1rem", "2.125rem"]}
            fontWeight={[600, 600, 600, "unset"]}
          >
            {title}: {convertTimeToString(date)}
          </Typography>
        </Box>
      </Box>
    </LoadingSkeleton>
  );
};

export const TransactionImageCarousel: React.FC<TransactionImageCarouselProps> = ({
  height = 404,
  loading = false,
  parkStartDate,
  parkStartImageSrc,
  parkEndDate,
  parkEndImageSrc,
  licensePlateCaptureDate,
  licensePlateCaptureImageSrc,
}) => {
  const transactionImageCarouselItems =
    parkStartDate &&
    parkStartImageSrc &&
    parkEndDate &&
    parkEndImageSrc &&
    licensePlateCaptureDate &&
    licensePlateCaptureImageSrc
      ? [
          {
            date: parkStartDate,
            title: "Park Start",
            imageSrc: parkStartImageSrc,
          },
          {
            date: parkEndDate,
            title: "Park End",
            imageSrc: parkEndImageSrc,
          },
          {
            date: licensePlateCaptureDate,
            title: "License Plate Capture",
            imageSrc: licensePlateCaptureImageSrc,
          },
        ]
      : [];

  return loading ? (
    <Skeleton variant="rectangular" width={"100%"}>
      <Box width={"100%"} height={height} />
    </Skeleton>
  ) : (
    <Carousel
      swipe={true}
      navButtonsAlwaysVisible={true}
      indicators={false}
      PrevIcon={<CarouselLeftButton />}
      NextIcon={<CarouselRightButton />}
      navButtonsProps={{
        // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
        style: {
          backgroundColor: "transparent",
          padding: 0,
        },
      }}
      height={height}
    >
      {transactionImageCarouselItems.map((item, i) => (
        <TransactionImageCarouselItem key={i} date={item.date} title={item.title} imageSrc={item.imageSrc} />
      ))}
    </Carousel>
  );
};

export interface TransactionImageCarouselItemProps {
  date: Date;
  title: string;
  imageSrc: string;
}

export interface TransactionImageCarouselProps {
  height?: number;
  loading?: boolean;
  parkStartDate?: Date;
  parkStartImageSrc?: string;
  parkEndDate?: Date;
  parkEndImageSrc?: string;
  licensePlateCaptureDate?: Date;
  licensePlateCaptureImageSrc?: string;
}

export default TransactionImageCarousel;
